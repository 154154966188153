.form,
form {

  .form-group {
    position: relative;
    margin: 0 0 10px 0;
  }

  //use for multiple fields in a row
  .left {
    margin-right: 5px;
  }

  .center {
    margin-right: 5px;
    margin-left: 5px;
  }

  .right {
    margin-left: 5px;
  }


  .input-text,
  .input-text:focus {
    border-radius: 4px;
    border: solid 1px #bec4d7;
    padding: 11px 16px;
    margin: 0;
    height: 42px;
    width: 100%;
    font-weight: 500;
    font-size: 14px;
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;

    @include media-breakpoint-down(sm) {
      height: 48px;
    }

    &::placeholder {
      color: #bec4d7;
      font-size: 14px;
      font-weight: 500;
    }
  }
  .form-message{
    clear: both;
  }
  .form-monetaire{
    padding-left: 16px;
    display: inline-block;
    float: right;
    margin-top: 15px;
    .label-monetaire{
      font-size: 24px;
      font-weight: bold;
      color: #00cfc4;
      margin-right: 10px;
    }
    .input-monetaire {
      font-size: 24px;
      color: #00cfc4;
      font-weight: bold;
      padding: 5px;
      text-align: right;
      border: none;
      display: inline-block;
      outline: none;
      margin-top: -5px !important;
      &.input-monetaire-70{
        width: 70px;
      }
      &.input-monetaire-300{
        width: 300px;
      }
      &::placeholder {
        font-size: 24px;
        color: #00cfc452;
        font-weight: bold;
      }
    }
  }
  .parsley-error,
  .parsley-error:focus {
    border-color: #ffb0ba;
    background: #fff7f8;
    position: relative;
    margin-top: 24px;

    & + ul {
      position: absolute;
      top: -24px;
      list-style: none;
      padding: 2px 8px;
      background: #ff7889;
      border-radius: 4px;

      &:after {
        content: "";
        position: absolute;
        bottom: -7px;
        left: 0;
        width: 0;
        height: 8px;
        border: 8px solid transparent;
        border-left: 10px solid #f78;

      }

      li {
        font-size: 12px;
        font-weight: 600;
        color: #fff;
      }
    }
  }

  .bottom {
    @extend .d-flex,
    .bt,
    .p-16,
    .justify-content-end;

    width: 100%;

    .btn {
      margin: 0 0 0 20px;
      width: auto;

      &:first-of-type {
        margin-left: 0;
      }
    }
  }

  .error-alert {
    background: #FF7889;
    padding: 4px 12px;
    margin: 0 0 8px;
    border-radius: 4px;
    color: #fff;
    font-size: 12px;
    font-weight: 500;
  }

  .invalid-feedback {
    display: none;
    position: absolute;
    top: -15px;
    width: 100%;
    margin: 0;
    letter-spacing: 0.04em;
    font-size: 10px;
    color: #f4516c;
    padding: 0;
    background-color: transparent;
  }
}

.input-drag {
  display: none;
}

.drop-area {
  position: relative;
  width: 100%;
  height: 126px;
  border-radius: 4px;
  background-color: rgba(246, 249, 252, 0.6);
  border: dashed 2px #bec4d7;

  .drop-text {
    position: absolute;
    width: 100%;
    text-align: center;
    color: #7f879c;
    font-weight: 600;
    margin: 0;
    top: 50%;
    transform: translateY(-50%);
    @include media-breakpoint-down(sm) {
      font-size: 12px;
    }

    i {
      display: block;
      font-size: 32px;
      color: #fff;
      margin: 12px 0 0;
      @include media-breakpoint-down(sm) {
        font-size: 14px;
        margin: 0;
      }
    }
  }
}

.form-select {
  border: 1px solid #bec4d7;
  position: relative;
  border-radius: 4px;
  height: 32px;
  background: #fff;
  margin: 0 10px;

  .arrow {
    font-size: 8px;
    transform-origin: center;
    transform: translateY(-50%) rotate(90deg);
    margin: 0;
    position: absolute;
    right: 12px;
    top: 54%;
    color: #5f59ef;
  }

  select {
    display: inline-block;
    outline: none;
    border: none;
    vertical-align: middle;
    background: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    margin: 0 16px 0 0;
    padding: 2px 12px;
    width: 100%;
  }
}

.form-control {
  height: 42px;
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}


.error-message {
  display: none;
}

.form-group.error {
  .error-message {
    display: block;
    COLOR: #ff7687;
    font-size: 12px;
    font-weight: 600;
    letter-spacing: 0;
    margin: 16px 0 8px 0;
    line-height: initial;
  }

  .input-text {
    border-color: #ff7687;
  }
}

.form-group.success {
  .input-text {
    border-color: #00cfc4;
  }
}


.note-editor.note-frame {
  border: 1px solid #bec4d7 !important;
  border-radius: 4px;
  margin: 16px 0;

  .note-toolbar-wrapper {
    height: 42px !important;

    .btn {
      padding: 10px 13px 9px;
      border: 0 solid transparent;
      background-color: transparent;
      margin: 0;
      border-radius: 0;
      color: #99a2c2;
      font-size: 16px;

      &.active {
        background: #5f59ef;
        color: #fff;
      }

      &:after {
        display: none !important
      }
    }
  }

  .note-editing-area {
    border-top: 1px solid #e0e6f1;

    .card-header {
      padding: 0 !important;
      margin: 0 !important;
      z-index: 1;
      background-color: #f6f9fc !important;
      border-bottom: 1px solid #e0e6f1 !important;
      @extend .d-flex, .justify-content-between;

      &:first-child {
        border-radius: 4px 4px 0 0 !important;
      }

      .btn-group {
        margin-top: 0 !important;
        margin-right: 0 !important;
        margin-left: 0 !important;

        .note-btn {
          background: transparent;
          padding: 0 12px;
          margin: 0;
          border-radius: 0;

          &.dropdown-toggle:after {
            content: '';
          }
        }
      }

      .btn-light:not(:disabled):not(.disabled):active,
      .btn-light:not(:disabled):not(.disabled).active,
      .show > .btn-light.dropdown-toggle {
        color: transparent;
        background-color: initial;
        border-color: initial;

        i {
          color: #5b6a9a !important;
        }
      }

    }
  }

  .note-statusbar {
    display: none;
  }
}

.vendor-logo {
  .edit-btn {
    position: absolute;
    top: 0;
    right: 0;
    padding: 8px;
  }
}

.descriptionPro .note-color, .descriptionPro .note-style{
  display: inline-flex !important;
}
.descriptionPro .note-color-palette .note-color-btn{
  height:20px;
  width:20px;
  padding: 0;
    margin: 0;
    border: 1px solid #fff;
}
.descriptionPro .note-palette{width:160px;}
.descriptionPro div .note-color .note-palette .note-holder, .descriptionPro .note-color-reset.btn.btn-light {display:none;}
.descriptionPro div .note-color .note-palette .note-palette .note-holder, .descriptionPro .note-palette .note-palette .note-color-reset.btn.btn-light {display:inherit;}
.descriptionPro .panel-heading.note-toolbar .note-color .dropdown-menu .note-palette .note-color-reset {
  width: 100%;
  padding: 0 3px;
  margin: 3px;
  font-size: 11px;
  cursor: pointer;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.descriptionPro div.note-btn-group .note-palette-title {display:none;}
.descriptionPro div.note-btn-group .note-icon-font.note-recent-color{background:none !important;}
.descriptionPro .note-editor.note-frame .note-toolbar-wrapper .btn{border:1px solid #e0e6f1;border-bottom:none;}
.descriptionPro .note-editor.note-frame .note-toolbar-wrapper.panel-default{border-bottom:1px solid #e0e6f1;}
.descriptionPro .panel-heading.note-toolbar .note-color .dropdown-toggle {
  width: 20px;
  padding-left: 5px;
}


/* =======================
        CHECKBOX
======================= */

.custom-checkbox {
  display: block;
  position: relative;
  margin: 0;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;


  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  [type="radio"]:checked,
  [type="radio"]:not(:checked) {
    position: absolute;
    left: -9999px;
  }

  [type="radio"]:checked + span,
  [type="radio"]:not(:checked) + span,
  .checkmark {
    display: inline-block;
    vertical-align: middle;
    //margin: 0 auto;
    height: 20px;
    width: 20px;
    border: 1px solid #bec4d7;
    border-radius: 12px;
    color: #fff;
    font-size: 10px !important;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  input:checked ~ .checkmark {
    background-color: #90e7b4;
    border: 1px solid #90e7b4;
  }

  [type="radio"]:not(:checked) + span:after,
  .checkmark:before {
    display: none;
  }

  input:checked ~ .checkmark:before {
    display: block;
    position: absolute;
    -webkit-animation-name: zoomIn;
    animation-name: zoomIn;
    -webkit-animation-duration: 0.2s;
    animation-duration: 0.2s;
    -webkit-animation-fill-mode: both;
    animation-fill-mode: both;
    transition: all 0.2s ease;
  }

  input:checked ~ .checkmark.squared {
    background-color: #5f5aef;
    border: 1px solid #5f5aef;
  }

  .checkmark.squared {
    border-radius: 3px !important;
  }


  .switch {
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 30px;
    height: 22px;
    width: 40px;
    border: 1px solid #bec4d7;
    margin-left: 8px;

    &:before {
      position: absolute;
      left: 0;
      top: 0;
      content: "";
      height: 20px;
      width: 20px;
      background: #848f9a;
      border-radius: 22px;
      transition: all ease-in-out 0.16s;
    }
  }

  /* When the checkbox is checked, add a blue background */
  input:checked ~ .switch {
  }

  /* Show the checkmark when checked */
  input:checked ~ .switch:before {
    background: #5f5aef;
    position: absolute;
    left: initial;
    right: 0;
    transition: all ease-in-out 0.16s;
  }

  input ~ .h2 {
    color: #5b6a9a !important;
  }

  input:checked ~ .h2 {
    color: #5e53f3 !important;
  }
}


/* =======================
      INPUT NUMBER
======================= */


input[type="number"] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

.input-number {
  background: #d9dce8;
  border-radius: 20px;
  width: 100px;
  display: flex;
  height: 36px;
  padding: 4px 0;

  .value {
    width: calc(100% - 50px);
    background: transparent;
    border: none;
    box-shadow: none;
    color: #fff;
    font-weight: 800;
    text-align: center;
    font-size: 20px;
    cursor: pointer;
  }

  .action {
    width: 25px;
    font-size: 16px;
    padding: 0 4px;
    font-weight: 600;
    color: #99a1c3;
    cursor: pointer;

    &.left {
      margin-right: 0;
      padding-right: 0;
    }

    &.right {
      margin-left: 0;
      padding-left: 0;
    }
  }
}

.inline-checkbox {
  display: inline-block;
  padding: 0 4px 0 0;
  vertical-align: middle;
}


/* =======================
        TABLE
======================= */

.table {

  .table-content {
    @extend .no-gutters, .p-16;
  }

  .table-row {
    padding: 12px 0;
    color: #99a1c3;
    font-weight: 500;
  }

  .table-header {
    font-weight: 600;
    padding: 8px 0;
    color: #99a2c2;

    .table-cell {
      font-size: 14px;
      @include media-breakpoint-down(sm) {
        font-size: 13px;
      }
    }
  }

  .table-cell {
    &.right {
      text-align: right;
    }

    &.center {
      text-align: center;
    }

    &.left {
      text-align: left;
    }

    &.push-right {
      @extend .mr-auto;
    }

    &.push-left {
      @extend .ml-auto;
    }

    &.tiny {
      padding: 0.75rem 4px;
    }

    .switch {
      margin: 0;
    }

    font-size: 14px;
    @include media-breakpoint-down(sm) {
      font-size: 12px;
      & span {
        font-size: 12px;
      }
    }
  }
}

/*
.table > thead > tr:first-child > th:first-child {
    position: absolute;
    display: inline-block;
    background-color: white;
    height: 67px;
    border-right: 2px solid #5e54f3;
}

.table > tbody > tr > td:first-child {
    position: absolute;
    display: inline-block;
    background-color: white;
    height: 67px;
    border-right: 2px solid #5e54f3;
}

.table > thead > tr:first-child > th:nth-child(2) {
    padding-left: 160px !important;
    @include media-breakpoint-down(sm) {
        min-width: 140px;
    }
}
.table > tbody > tr > td:nth-child(2) {
    padding-left: 160px !important;
    @include media-breakpoint-down(sm) {
        min-width: 140px;
    }
}
*/


/* =======================
      BLOC EDITION
======================= */

/*
.editing{

    &.bloc-phone{height: 300px;}
    &.bloc-email{height: 300px;}
    &.bloc-address{height: 300px;}
    &.bloc-info{
        height: 300px;
        @include media-breakpoint-down(sm) {
            height: 520px
        }
    }
}
*/

.modal-section {
  .btn-loader {
    padding-right: 56px;
    position: relative;
  }

  .loader {
    display: none;
  }
}

.bloc-editing {
  @extend .form, .animated, .faster;
  position: fixed;
  background: rgba(20, 18, 51, 0.4);
  border: none;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  z-index: 1000;
  @include media-breakpoint-down(sm) {
    background: #ffffff;
    -webkit-animation-name: slideInRight;
    animation-name: slideInRight;
  }

  &.fixed {
    display: none;
  }

  .modal-editing {
    position: relative;
    width: 100%;
    max-width: 800px;
    max-height: 70%;
    left: 50%;
    top: 20%;
    transform: translate(-50%, 0);
    overflow: auto;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
      width: 100%;
      max-height: 100%;
      height: 100%;
      left: 0;
      top: 0;
      padding-bottom: 80px;
      transform: none;
      overflow: auto;
      border: none;
    }
    .modal-editing.modal-seo{
      max-width: 900px;
      @include media-breakpoint-down(sm) {
        max-width: 100%;
      }
    }
  }


  h4,
  h2 {
    color: #5f5aef !important;
  }

  .top {
    padding: $gutter/4*3 $gutter;
    @include media-breakpoint-down(sm) {
      padding: $gutter/4*3 $gutter/2;
    }

    .h2, .h3, .h4 {
      color: #5f5bef;
    }
  }

  .content {
    padding: $gutter/4*3 $gutter;
    @include media-breakpoint-down(sm) {
      padding: $gutter/4*3 $gutter/2;
    }
  }

  .bottom {
    padding: $gutter/4*3 $gutter;
    position: absolute;
    bottom: 0;
    z-index: 1;
    @include media-breakpoint-down(sm) {
      padding: 0 $gutter/4*3 $gutter/2;
      position: relative;
      border: none;
    }
  }

  .modal-editing {
    .bottom {
      position: sticky;
      padding-bottom: $gutter/4*3;
      background: #fff;
      @include media-breakpoint-down(sm) {
        position: initial;
        border: none;
        padding-bottom: $gutter;
      }
    }
  }

  .loader {
    display: none;
  }

  .btn-loader {
    padding-right: 56px;
    position: relative;

    .loader {
      display: block !important;
      right: 16px;
      margin: 0;
      font-size: inherit;
      position: absolute;
      float: right;
      top: 50%;
      height: 24px;
      width: 24px;
      transform: translateY(-50%);

      &:after {
        content: "\F110";
        font-size: 24px;
        position: absolute;
        right: 0;
        top: 0;
        color: #ffffff;
        font-family: Font Awesome\ 5 Free;
        -webkit-animation: loader-rotate 1.6s cubic-bezier(0.94, 0.29, 0.51, 0.82) infinite;
        animation: loader-rotate 1.6s cubic-bezier(0.94, 0.29, 0.51, 0.82) infinite;
      }
    }
  }
}


.country-select {
  display: block !important;
}


.delete-picture {
  padding: 0;
  margin: 0;
  background: #ff7889;
  border: 1px solid #ff7889;
  height: 22px;
  width: 22px;
  display: flex;
  right: -8px;
  top: -8px;
  position: absolute;
  border-radius: 22px;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &:before {
    font-size: 10px;
    color: #fff;
  }
}

.simple-circle {
  @extend .fa;
  color: #fff;
  height: 22px;
  width: 22px;
  margin: 0;
  top: -8px;
  right: -8px;
  text-align: center;
  background: #FFF;
  border: 1px solid #bec4d7;
  display: block;
  position: absolute;
  border-radius: 20px;
}

.loader-circle {
  &:after {
    /* display: none; */
    content: "";
    border: 3px solid;
    border-color: transparent transparent transparent #5f59ef;
    -webkit-border-radius: 100px;
    -moz-border-radius: 100px;
    border-radius: 100px;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 0;
    position: absolute;
    animation: loader-rotate 2s linear infinite;
  }
}

.check-circle {
  @extend .fa;
  height: 22px;
  width: 22px;
  margin: 0;
  top: -8px;
  right: -8px;
  text-align: center;
  background: #fff;
  border: 1px solid #bec4d7;
  display: flex;
  position: absolute;
  border-radius: 20px;
  justify-content: center;
  align-items: center;

  &:before {
    content: "\f00c";
    font-size: 10px;
    color: #5f59ef;
  }
}

.error{
  color: #f4516c;
}

.cbtoken.bloc{
  .top{
    position: sticky;
    top: 0;
    border-bottom: 1px solid #e0e6f1 ;
    background: white;
    z-index: 1;

    h2{
      margin-bottom: 0;
    }
  }
}