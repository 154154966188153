.datatable{
  border: 1px solid #dee2e6;
  padding: 0;

  &.table{
    .table-header{
      font-weight: 600;
      padding: 8px 0;
      color: #37385e;
      background: #e0e6f0;
    }
  }
  tbody{
    background-color: white;
  }
}
table.table-user-manager {
  thead {
    th {
      font-size: 14px;
    }
  }
  tbody {
    td {
      font-size: 14px;
      font-weight: 400;
      color: #1F3349;
      vertical-align: middle;

      b {
        font-weight: 600;
      }
      i {
        font-size: 13px;
      }
    }
  }
}
.user-manager-tab__content {
  .vgt-wrap__footer {
    .footer__row-count {
      &::after {
        margin-top: -7px;
      }
      .footer__row-count__label, .footer__row-count__select {
        font-size: 14px;
        vertical-align: top;
      }
    }
    .footer__navigation {
      &__page-btn span {
        font-size: 14px;
      }
      .page-info__label {
        font-size: 14px;
      }
      button {
        vertical-align: top;
      }
    }
  }
}
