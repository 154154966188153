.home-slider {
    max-height: 600px;
}

.chena-section{
    .container {
        padding-top: $gutter*2;
        padding-bottom: $gutter*2;
        @extend .bb;
    }
}

.open-video {
    @extend .bloc;
    height: 120px;
    background: url(/img/chenas/slider-1.jpg) no-repeat center;
    background-size: cover;
    width: 180px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    transition: all 1s ease;

    &:hover {
        opacity: 0.9;
    }

    span {
        color: #fff;
        opacity: 0.9;
        font-size: 24px;
        border-radius: 40px;
        padding: 14px 3px 10px 10px;
        border: 4px solid #fff;
        width: 60px;
        height: 60px;
        text-align: center;
    }
}

.popup-video {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    max-width: 600px;
    margin: auto;
    max-height: 500px;
    height: 100%;
    background: #f4f7fb;
    padding: 0;
    border-radius: 4px;
    overflow: hidden;
    z-index: 101;
    @include media-breakpoint-down(sm) {}

    .close-video {
        position: absolute;
        top: 0;
        right: 0;
        padding: 24px 24px 24px 30px;
        font-size: 24px;
        z-index: 111111;
        cursor: pointer;
        background: #fff;
        margin: 0;
        text-align: center;
    }
}

.section-header {
    position: relative;
    height: 280px;
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
        content: "";
        position: absolute;
        background: rgba(29, 34, 49, 0.2);
        left: 0;
        top: 0;
        height: 100%;
        width: 100%;
    }

    .section-title {
        .h1,
        p {
            font-weight: 800;
            color: #fff;
            font-size: 34px;
            text-shadow: 0 0 16px #000;
            margin: 0;
        }
        p {font-size: 16px;}
    }
}

.list {
    li {
         @extend .p;
    }
}


.potiers-header {
    img {
        width: 100%;
    }
    .container {
        position: absolute;
        bottom: 16px;
        right: 16px;
        max-width: 420px !important;
        h1 {
            background: #fff;
            display: inline-block;
            padding: 4px;
        }
        h3 {
            color: #fff;
            background: #000;
            padding: 12px;
            font-size: 16px;
            line-height: 19px;
        }
    }
}

.home-category {
    img {
        width: 100%;
    }
}




.station-link {
    display: block;
    width: 100%;
    min-height: 208px;
    @include media-breakpoint-down(md) {min-height: 180px;}
    @include media-breakpoint-down(sm) {height: auto;}
    margin: 0 auto 32px;
    padding: 12px;
    opacity: 0.8;
    text-align: center;

    img {
        width: 100%;
    }

    &:hover,
    &:active {
        opacity: 1;
        p {
            color: #4843ed;
        }
    }
}
