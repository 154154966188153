
/* ================================
Classe principale qui porte le code
principal de tous les boutons.
================================ */

.btn {
    display: inline-block;
    position: relative;
    line-height: normal;
    font-size: 14px;
    font-weight: bold;
    text-align: center;
    padding: 10px 12px;
    margin: 0 0 8px;
    border: 1px solid transparent;
    border-radius: 4px;
    outline: none;
    transition: all .15s ease-in-out;
    opacity: 0.98;

    &:hover {
        opacity: 0.85;
    }

    &:active {
        opacity: 1;
    }

    .icon,
    .fa,
    .fas {
        font-size: inherit;
    }

    @include media-breakpoint-down(sm) {
        padding: 13px 12px 14px;
        font-size: 15px;
    }
}


/***************************************
==>  Ancien style avant la Documentation
Ci-dessous on défini les variations des boutons.
***************************************

/* ======== Bouton plain ======== */
.btn-main {
    background-color: #5f5aef;
    color: #fff;

    &:hover {
        background-color: #4843ed;
        color: #fff;
    }

    &:active {
        background-color: #7571f1;
        color: #fff;
    }
}


/* ======== Bouton filaire ======== */
.btn-secondary {
    border: solid 1px #bec4d7;
    background: transparent;
    color: #80889c;

    &:hover {
        background-color: #f9f9fb;
        color: #bec4d7;
        border-color: #bec4d7;
    }

    &:active {
        outline: none;
        background: transparent !important;
        border-color: #7571f1 !important;
        color: #7571f1 !important;
    }

    &.red {
        border: solid 1px #ff7889;
        background: transparent;
        color: #ff7889;
    }
}


/* ======== Bouton Arrondi ======== */
.btn-rounded {
    border-radius: 38px !important;
    padding: 9px 12px;
    @include media-breakpoint-down(sm) {
        padding: 12px;
    }
}


/* ======== Bouton rond avec icon ======== */
.btn-icon {
    width: 38px !important;
    height: 38px !important;
    padding: 8px 0 9px;
    border-radius: 38px;
    font-size: 17.5px;

    @include media-breakpoint-down(sm) {
        width: 48px !important;
        height: 48px !important;
        padding: 12px 0;
        span {
            font-size: 22px !important;
        }
    }
}


/* ======== Bouton PIN (petit bouton de chiffres) ======== */
.pin {
    position: absolute;
    top: -1px;
    right: -4px;
    background: #ff7889;
    border-radius: 40px;
    z-index: 1;
    padding: 2px 4px;
    color: #fff;
    font-size: 10px;
}


/* ======== Couleurs des boutons ======== */
.btn-green {
    background-color: #90e7b4;

    &:hover,
    &:active {
        background-color: #6cd899;
    }
}
.btn-green2 {
    background-color: #01cfc4;
    &:hover,
    &:active {
        background-color: #04bfb5;
    }
}

.btn-green-2 {
    background-color: #00cfc4;

    &:hover,
    &:active {
        background-color: #00cfc4;
    }
}

.btn-red {
    background-color: #ff7889;

    &:hover,
    &:active {
        background-color: #ff7889;
    }
}



//=============================
//   BOUTONS D'ACTION
//=============================

.add-btn {
    text-transform: initial;
    font-weight: 800;
    color: #00cfc4;
    border: 1px solid #00cfc4;
    cursor: pointer;
    border-radius: 22px;
    padding: 4px 8px;
    font-size: 12px;
    margin-top: -3px;
    @include media-breakpoint-down(sm) {
        font-size: 10px;
        margin-top: -5px;
    }

    i {
        vertical-align: baseline;
        font-size: 13px;
        display: none;
    }

    &:hover,
    &:active {
        color: #f4f7fb;
        background: #00cfc4;
    }
}

.edit-btn {
    color: #5f5aef;
    cursor: pointer;
}

.delete-btn {
    color: #bec4d8;
    cursor: pointer;
}









// *****************************
// ** Nouvelle documentation **
//
//     BOUTONS PRINCIPAUX
//
// *****************************

.btn {
    @extend .btn;

    /* ========= Boutons Primaires ========= */
    &.main {
        background-color: #5f5aef;
        color: #fff;

        &.green {
            background-color: #00cfc4;
        }

        &.red {
            background-color: #ff7889;
        }
    }


    /* ========= Boutons Secondaires ========= */
    &.secondary {
        border: solid 1px #bec4d7;
        background: transparent;
        color: #80889c;

        &.green {
            border-color: #00cfc4;
            color: #00cfc4;
        }

        &.red {
            border-color: #ff7889;
            color: #ff7889;
        }
    }

    /* ========= Boutons Pleine Largeur ========= */
    &.full-width {
        display: block;
    }

    /* ========= Boutons Désactivé ========= */
    &.disabled {
        opacity: 0.5 !important;
        cursor: not-allowed;
    }

    /* ========= Boutons Arrondis ========= */
    &.rounded {
        border-radius: 38px !important;
    }

    /* ========= Petits Boutons rond Avec Icons ========= */
    &.icon {
        width: 38px !important;
        height: 38px !important;
        padding: 8px 0 9px;
        border-radius: 38px;
        font-size: 17.5px;

        @include media-breakpoint-down(sm) {
            width: 48px !important;
            height: 48px !important;
            padding: 12px 0;
            span {
                font-size: 22px !important;
            }
        }
    }


    /* ========= BOUTONS D'ACTION ========= */

    .add-btn {
        font-weight: 800;
        color: #00cfc4;
        border: 1px solid #00cfc4;
        border-radius: 22px;
        padding: 4px 8px;
        font-size: 12px;
        @include media-breakpoint-down(sm) {
            font-size: 10px;
        }

        &:hover,
        &:active {
            color: #f4f7fb;
            background: #00cfc4;
        }
    }

    .edit-btn {
        color: #5f5aef;
        cursor: pointer;
    }

    .delete-btn {
        color: #bec4d8;
        cursor: pointer;
    }

}




//=============================
//      Loader in button
//=============================


.loader {
    @extend .fas;
    display: none;
    position: absolute;
    top: 50%;
    right: 12px;
    transform: translateY(-50%);
    height: 24px;
    width: 24px;
    margin: 0;

    &:after {
        content: "\f110";
        font-family: Font Awesome\ 5 Free;
        font-size: 24px;
        color: #bec4d7;

        position: absolute;
        left: 0;
        top: 0;

        -webkit-animation: loader-rotate 1.6s cubic-bezier(0.94, 0.29, 0.51, 0.82) infinite;
        -moz-animation: loader-rotate 1.6s cubic-bezier(0.94, 0.29, 0.51, 0.82) infinite;
        -ms-animation: loader-rotate 1.6s cubic-bezier(0.94, 0.29, 0.51, 0.82) infinite;
        -o-animation: loader-rotate 1.6s cubic-bezier(0.94, 0.29, 0.51, 0.82) infinite;
        animation: loader-rotate 1.6s cubic-bezier(0.94, 0.29, 0.51, 0.82) infinite;
    }
}

.table-loader,
.table-placeholder {
    .loader {
        display: block;
        position: initial;
        margin: 24px auto;

        &:after {
            color: #5f5aef;
        }
    }

    p {
        position: initial;
        margin: 24px auto;
    }
}

.btn {
    .loader:after {
        color: #fff;
    }
}

.btn-loader {
    .loader {
        display: block !important;
    }
}

.btn.loading {
    padding-right: 48px;
    .loader {
        display: block !important;
    }
}





















