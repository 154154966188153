/* ===========================================
 Slideshow d'images utilisé pour les top page
=========================================== */


.hero-slideshow {}

.complex-slider {
    width: 100%;
    height: 600px;
    @include media-breakpoint-down(md) {height: 400px;}
    @include media-breakpoint-down(sm) {height: 250px;}
    overflow: hidden;
    position: relative;
    &:after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /*background: #000;
        opacity: 0.2;*/
    }
    .container {
        position: relative;
        display: table;
        height: 100%;
        z-index: 2;

        h1 {
            color: #fff;
            text-transform: uppercase;
            font-weight: 800;
            font-size: 42px;
            text-shadow: 0 0 12px rgba(0, 0, 0, 0.32);
            @include media-breakpoint-down(sm) {font-size: 22px;margin-bottom: 12px;}
        }
        h2 {
            color: #fff;
            font-weight: 600;
            font-size: 22px;
            text-shadow: 0 0 12px rgba(0, 0, 0, 0.32);
            margin-bottom: 24px;
            @include media-breakpoint-down(sm) {font-size: 16px;margin-bottom: 12px;}
        }

        .btn {
            width: auto;
            display: inline-block;
            @include media-breakpoint-down(sm) {padding: 6px 12px;}
        }
    }
}

.slider-text-wrap {
    display: table-cell;
    &.vertical-top {vertical-align: top}
    &.vertical-center {vertical-align: middle}
    &.vertical-bottom {vertical-align: bottom}
    &.horizontal-left {text-align: left}
    &.horizontal-center {text-align: center}
    &.horizontal-right {text-align: right}
    @include media-breakpoint-down(sm) {
        vertical-align: middle !important;
        text-align: center !important;
    }

    .slick-dots {
        position: absolute;
        bottom: -24px;
        left: 50%;
        li {
            font-size: 8px;
            margin: 8px 4px 0;
            cursor: pointer;
        }
    }
}
