.hero-image-brands {
    position: relative;
    background: url('../img/background-marques.jpg') no-repeat center bottom;
    z-index: 0;
    padding-bottom: 60px;

    &:before {
        content: '';
        //background: url('../img/brands-hero.jpg') center bottom no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0.8;
        z-index: -1;
    }
    h1 {
        color: #fff;
        text-shadow: 0 0 12px #0f1a56a6;
    }
}

.brands {
    & .container:first-of-type {
        margin-top: -64px;
    }

    .brand-search-bloc {
        .search-form {
            width: 100%;
        }
    }
    .brand-list {
        div{
            cursor: pointer;
        }
        img {
            @extend .bloc;
            display: inline-block;
            border: 1px solid #e0e6f1 !important;
            width: 36px !important;
            height: 36px !important;
        }
        span {
            font-weight: 500;
            font-size: 16px;
            margin-left: 8px;
        }
    }
}
#vueMarque{
    // position: relative;s
    #btnLoadMarques{
        // position: absolute;
        left: 50%;
        transform: translate(-50%);
        top: 200px;
    }
}

.contentListPDV{
    ul{
        padding: 0;
        img{
            max-width: 80px;
            margin-right: 10px;
            border: 1px solid #e0e6f1 !important;
        }
    }
}
.listePDV {
    .top img{
        margin-right: 10px;
    }
}

.listeMarquesGrp{
    padding-top: 0 !important;
}
