
//================
// Temporary slider
//================

.hero-slider {
    transition: all ease 2s;
    opacity: 1;
    transition-delay: 2s;

    margin-top: 0 !important;
    margin-bottom: 0 !important;
    background: #fff;

    h4 {
        padding: 24px 24px 16px;
        font-size: 12px;
        color: #81899C;
        text-align: center;
        font-weight: 600;
        text-transform: uppercase;
    }

    .brand-slider {
        padding: 0 24px 24px;
        margin-bottom: 0;

        img {
            height: 45px;
            padding: 0 32px;

            -webkit-filter: grayscale(20%);
            -moz-filter: grayscale(20%);
            -o-filter: grayscale(20%);
            -ms-filter: grayscale(20%);
            filter: grayscale(20%);
        }

        .slick-next {
            right: -10px;
            top: 30%;
        }
        .slick-prev {
            left: -10px;
            top: 30%;
        }

        .slick-prev:before,
        .slick-next:before {
            color: #5f5aef;
        }

        .slick-dots {
            bottom: 16px;

            li {
                height: 16px;
                width: 16px;

                button:before,
                .slick-active button:before {
                    color: #5f5aef;
                    font-size: 8px;
                }
            }

        }
    }
}





//=====================
// General slider style
//=====================


[class^="main-slider-"] {
    .bloc-style {
        margin: 20px 16px;
    }
}

.slider-nav,
[class^="slider-nav-"]{
    float: right;

    .slider-paging {
        opacity: .40;
        color: #5b6a9a;
        font-style: normal;
        margin-right: 14px;
    }

    .prev {
        transform: rotate(-180deg);
    }

    .prev,
    .next {
        font-size: 12px;
        padding: 4px 4px 3px;
        margin: 0;
        background: none;
        border: none;
        outline: none;
        opacity: .40;
        color: #5b6a9a;
    }

    .prev:hover,
    .next:hover {
        opacity: 1;
    }
}

.slick-dots {
    @extend .d-flex;
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        @extend .fa, .fas;
        font-family: Font Awesome\ 5 Free;
        font-style: normal;
        font-size: 4px;
        margin: 8px 2px 0;
        color: #bdc3d7;
        &.slick-active {
            color: #5f5aef;
        }
        &:before {
            content: "\f111";
        }

        button {
            display: none;
        }
    }
}

@include media-breakpoint-down(sm) {
    .slider-paging {
        display: none;
    }
}