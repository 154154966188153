.spcr-logo {
    text-align: center;
    img {
        max-height: 80px;
    }
    @include media-breakpoint-down(sm) {
        img {
            margin: auto;
            max-height: initial;
            width: 200px;
        }
    }
}
