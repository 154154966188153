.popup {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #fff;
    z-index: 101;
    overflow: auto;
}

.close-popup {
    position: absolute;
    top: 0;
    right: 0;
    margin: 0;
    padding: 16px;
    font-size: 22px;
    cursor: pointer;
}

.overlay {
    z-index: 100;
    content: "";
    position: fixed;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: rgba(13, 11, 47, 0.3);
}


/* ==================================
    Alert top popup notification
================================== */

.alert-container {
    position: fixed;
    top: 16px;
    right: 16px;
    @include media-breakpoint-down(sm) {
        right: 16px;
        left: 16px;
    }
    z-index: 1111111;
    max-width: 400px;
    height: auto;
}


.alert-popup {
    position: relative;
    display: block;
    margin: 0 0 16px;
    border-radius: 12px;
    overflow: hidden;
    color: #37385e;
    border: 1px solid #0000003b;
    background: #fff;
    box-shadow: -8px 8px 34px 0 rgba(74, 144, 226, 0.16);
    transition: all 0.5s cubic-bezier(0.63, 0.05, 0.38, 1.22);
    @extend .slideInDown, .animated, .faster;

    .alert-close {
        position: absolute;
        top: 0;
        right: 0;
        padding: 12px;
        margin: 0;
        cursor: pointer;
        opacity: 0.6;
        &:hover {opacity: 1;}
    }

    .content {
        align-items: center;
        padding: 24px 36px 24px 24px;
        margin-left: 6px;
        background: #fff;

        .alert-icon {
            margin: 0 16px 0 0;
            font-size: 20px;
        }

        .alert-text {
            font-size: 12px;
            text-transform: uppercase;
            letter-spacing: 0.04em;
            font-weight: 600;
        }
    }
}

.alert-danger {
    border-color: #ff7889;
    background: #ff7889;
    .alert-icon {color: #ff7889}
}

.alert-info {
    border-color: #5f5aef;
    background: #5f5aef;
    .alert-icon {color: #5f5aef}
}

.alert-success {
    border-color: #00cfc4;
    background: #00cfc4;
    .alert-icon {color: #00cfc4}
}