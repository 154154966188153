.fidelitesGrp, .Nombre_chequiers, .cagnotte {
  .col-fidelites, .col-cagnotte, .col-Nombre_chequiers{
    margin-top: 2rem;
    margin-bottom: 2rem;
    .loyalty-program {
      .badge .badge-value {
        font-size: 14px;
      }
      .square-image {
        img {
          position: absolute;
          /* left: 0; */
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          width: 120px;
          height: 120px;
        }
      }
    }
  }
}