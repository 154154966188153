.hero-proPage-section {
  position: relative;
  height: 360px;
  margin-bottom: 100px;

  &.small-hero {
    height: 240px;
  }

  .section-background {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #5f59ef;
    overflow: hidden;
    .cropVitrine.slim>img {
      width: 100% !important;
      height: inherit !important;
    }

    .edit-vitrine-btn {
      color: #fff;
      margin-top: 6px;
    }

    img {
      width: 100%;
      opacity: 0.3;
    }
  }

  .container {
    height: 100%;
    position: relative;
  }


  .vendor-info-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: -70px;
    margin: 0 15px 0 55px;
    padding: 15px;
    border-radius: 4px;
    background-color: #fff;
    box-shadow: 0 22px 54px 0 rgba(74, 144, 226, 0.1);

    .vendor-logo {
      height: 178px;
      width: 178px;
      margin: 0 15px 0 -55px;
      overflow: hidden;
      float: left;
      box-shadow: 12px 6px 14px 0 rgba(74, 144, 226, 0.1);

      img {
        max-height: 100%;
        max-width: 100%;
        padding: 0;
      }
    }

    .vendor-category {
      font-weight: 600;
      color: #bdc6d1;
    }

    .vendor-shop-name {
      min-height: 66px;
    }

    .btn {
      margin: 0 10px 0 0;
      &.contact-btn {
        width: auto;
      }
    }

    .share-btn {
      color: #1f3349;

      span {
        padding: 10px;
      }
    }
  }

  .vendor-address-container {
    border-left: solid 1px #e7e7e7;
  }

  .vendor-opening-info,
  .vendor-address-info {
    padding: 25px 0 0 44px;
    position: relative;

    .fa-clock,
    .fa-map {
      position: absolute;
      left: 8px;
      margin: 2px 0 0 0;
      font-size: 22px;
      color: #bec4d7;
    }

    .opening-title,
    .address-title {
      color: #5b6a9a;
      margin: 0;
      font-weight: 600;
    }

    .opened-state {
      color: #90e7b4;
    }
    .closed-state {
      color: #ff7889;
    }
  }
}

.description-placeholder {
  text-align: center;
  padding: 80px 40px;
}

.vendor-section-boutique {
  .m-logo {
    background-position: center;
    background-size: cover;
    width: 100%;
    height: 400px;
    background-repeat: no-repeat;
  }
  .m-portlet--articles.Bons-plans-size {
    -webkit-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    -moz-box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    box-shadow: 0 1px 15px 1px rgba(69, 65, 78, 0.08);
    background-color: #fff;
    margin-bottom: 2.2rem;
    h5 {
      font-size: 1rem;
      color: #5c5962;
    }
    .Remise{
      float: left;
      padding-left: 9px;
      padding-right: 9px;
      margin-left: 15px;
      margin-top: 15px;
      height: 30px;
      font-size: 12px;
      line-height: 30px;
      text-align: center;
      color: white;
      border-radius: 5px;
      background: #B22222;
    }
    .m-widget14__header span {
      color: #ada7a8;
      font-size: 0.9rem;
    }
    .libelle.prix {
      font-weight: 500;
      color: red;
      font-size: 22px;
      line-height: 22px;
      margin-top: 10px;
      margin-bottom: 5px;
    }
    .m-portlet__foot {
      padding: 0rem 1rem;
      border-top: 1px solid #ebedf2;
      .btn-modifs-mobile{
        margin-bottom: 10px;
        margin-top: 10px;
      }
      .btn-sm {
        font-weight: 400;
        color: #5867dd;
        font-size: 0.8rem;
        padding: 0.35rem 0.75rem;
        margin-bottom: 0;
      }
      .btn-success {
        background-color: #34bfa3;
        border-color: #34bfa3;
        padding: 0.35rem 0.75rem;
        font-size: 0.875rem;
        line-height: 1.5;
        border-radius: 0.2rem;
        color: #fff;
        margin-bottom: 0;
      }
    }
  }
}
.opening-table-row {
  padding: 24px 12px;
  width: 100%;
  min-height: 339px;

  .closed-state {
    color: #ff7889;
  }

  tr {
    th,
    td {
      vertical-align: middle;
      border-top: 0 solid transparent;
      padding: 8px 0 !important;
      text-align: center;
    }

    .day {
      font-weight: 600;
      position: relative;
      width: 30%;
      padding-left: 16px !important;
      text-align: left;
      color: #bec4d7;

      span {
        &:after {
          content: "";
          width: 47px;
          border-bottom: solid 1px rgba(174, 185, 198, 0.3);
          top: 20px;
          position: absolute;
          right: 22px;
        }
      }
    }

    .dot-separator span {
      color: #d8d8d8;
      font-size: 28px;
      line-height: 15px;
    }

    .hours {
      padding-right: 16px;
      span {
        text-align: center;
        display: inline-block;
        &:nth-of-type(1) {
          width: 42%;
        }
        &:nth-of-type(2) {
          font-size: 22px;
          line-height: 0;
          font-weight: bold;
        }
        &:nth-of-type(3) {
          width: 42%;
        }
      }
    }
  }

  tr.active {
    th,
    td,
    span {
      font-weight: 600;
      color: #fff;
      background-color: #90e7b4;
    }
    .day {
      border-radius: 4px 0 0 4px;
    }
    .day span:after {
      border-color: #fff;
    }
    .hours {
      border-radius: 0 4px 4px 0;
    }
    .dot-separator span {
      font-size: 28px;
      line-height: 20px;
      font-weight: 500;
    }
  }
}

.maps-container {
  height: 339px;
  border-radius: 4px;
  background-color: #f0f5fc;
  position: relative;
  padding: 0 !important;
  overflow: hidden;

  .mapboxgl-ctrl-bottom-left,
  .mapboxgl-ctrl-bottom-right {
    display: none;
  }

  .maps-bottom {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #fff;
    padding: 15px 25px;
    z-index: 1;

    .maps-address {
      display: block;
      margin: 0;
    }
  }
}

.title-light {
  opacity: 0.4;
}

.loyalty-program {
  position: relative;
  margin: 0;
  padding: 0;

  .badge {
    position: absolute;
    top: -12.5px;
    right: 16px;
    background: #ff7889;
    border-radius: 40px;
    z-index: 1;
    padding: 4px 12px;

    .badge-value {
      color: #fff;
      font-size: 11px;
      vertical-align: middle;
      display: inline-block;
    }
  }

  .square-image {
    width: 120px;
    height: 120px;
    position: relative;
    display: inline-block;
    vertical-align: middle;
    border-radius: 4px 0 0 4px;
    background: #e0e6f1;
    padding: 30px 12px !important;

    img {
      width: 100%;
    }
  }

  .program-description {
    display: inline-block;
    vertical-align: middle;
    width: calc(100% - 140px);
    padding: 0 0 0 8px;
  }

  .text-title {
    font-weight: 800;
    margin: 0;
  }
}

.actu-bloc {
  margin: 0 0 32px 0;

  .actu-content {
    padding: 32px 24px 8px;
  }

  &.bloc-editing .top {
    padding-bottom: 81px;
  }

  .add-logo-item {
    margin: 0 0 24px;
  }

  .note-popover .popover-content,
  .card-header.note-toolbar {
    padding: 0;
    margin: 0;
  }

  .card-header.note-toolbar > .btn-group {
    margin-top: 0;
    margin-right: 0;
    margin-left: 0;
  }
}

.logo-slider .slick-list.draggable {
  width: 100% !important;
}

.galery-slider {
  .slick-slide {
    height: 390px;
  }
}
.slider-brand-logo {
  height: 168px;
  width: 168px;
  margin: 0 !important;
  position: relative;

  img {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 100%;
    padding: 20px !important;
  }
}

/*.create-actu {
    border: 1px solid #5f59ef;

    .actu-header {
        border: none;
    }

    .actu-content {
        padding: 0 20px 25px;
    }

    .btn-main {
        width: 120px;
        margin: 16px 0 0 0;

        &.facebook-btn {
            background: #bec4d7;
            margin-right: 8px;
        }
    }
}
*/

.actu-header {
  border-bottom: 1px solid #dddfe4;
  max-height: 70px;
  padding: 16px 24px;

  .pro-logo {
    height: 48px;
    width: 48px;
    // border: 1px solid #bec4d7;
    // border-radius: 4px !important;

    img {
      // border-radius: 4px;
      width: 100%;
    }
  }

  .pro-name {
    margin-left: 16px;
    font-size: 17px;
    font-weight: 700;
    color: #365899;
  }

  .create-date {
    margin-left: 16px;
    color: #6B6B6B;
    font-size: 14px;
    font-weight: 500;
    span {
      font-weight: 700;
    }
    // font-family: Roboto;
  }
}
.logo-slider{
  .slick-track{
    margin-left: 0 !important;
}
}
.vs__dropdown-menu li{
  border-bottom: 1px solid #e0e6f1 !important;
}
.actu-content {
  justify-content: space-between;

  .actu-image {
    width: 48%;
    // width: 100%;
    margin: 0;
    height: 240px;
  }

  .actu-content {
    width: 48%;
    padding: 0rem 1.4rem 0rem 0.6rem;
  }

  .actu-title {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 25px;
    margin-top: 11px;
    color: #365899;
  }
}

.vendor-logo {
  .edit-btn {
    position: absolute;
    top: 6px;
    right: 0;
    padding: 8px;
    background: #fff;
    border-radius: 20px;
  }
  .bloc-editing {
    height: 100%;
    .add-logo-item {
      margin: 0;
      background-size: cover;
      .drop-area {
        height: 174px;
        background-color: #5b6a9a63;
        border: none;
        font-weight: 600;
        font-size: 14px;
        @include media-breakpoint-down(sm) {
          font-size: 12px;
        }
        i {
          color: rgba(95, 89, 239, 0.7);
        }
      }
    }
  }
}

.small-logo-container {
  .simple-circle {
    @extend .fa;
    top: 8px;
    right: 8px;
  }
}

.actu-loader {
  position: relative;
  text-align: center;
  padding: 60px;
  justify-content: center;
  display: flex;

  .loader {
    position: relative;
    text-align: center;
    clear: both;
    right: 0;
    top: 0;
    transform: translate(0);
    display: flex;
    justify-content: center;
    align-items: center;

    &:after {
      font-size: 24px;
      color: #5f59ef;
      position: initial;
      left: initial;
      top: initial;
    }
  }
}

.bloc-image {
  height: 91px;
  margin-bottom: 16px;
  border-radius: 4px;
}

.bloc-admin-horaires {
  min-height: 340px;

  .form-group {
    margin-bottom: 16px !important;
  }

  .drop-list,
  .drop-list:hover,
  .drop-list:focus {
    @extend .fa;
    background: none;
    height: auto;
    padding: 0;
    border: none;
    color: #5e53f3;
    font-size: 14px;
    margin: 0;

    &:after {
      content: "\f04b";
    }
  }

  .time-picker-text {
    padding: 0 12px;
  }

  .el-date-editor.el-input {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .el-input__inner {
    @extend .input-text;
    width: 60px !important;
    @include media-breakpoint-down(sm) {
      width: 100% !important;
      margin-bottom: 8px;
    }
    padding: 0 !important;
    text-align: center;
    font-size: 12px !important;
  }
  .el-input__icon {
    display: none;
  }
  .custom-checkbox {
    font-size: 10px;
    color: #99a0c4;
    font-weight: 500;
  }
}

//==================================
// Medium devices (less than 1023px)
//==================================

@include media-breakpoint-down(md) {
  .hours-title {
    margin-top: 70px !important;
  }
}

//================================
// Small devices (less than 768px)
//================================

@include media-breakpoint-down(sm) {
  .loyalty-program {
    margin-bottom: 24px;
  }
  .flexReg{
    flex-wrap: wrap;
    justify-content: flex-start;
    div{
      margin: 0 5px;
    }
  }
  .hero-proPage-section,
  .hero-proPage-section.small-hero {
    height: auto;
    margin-bottom: 0;
    padding-top: 120px;
    background: #fff;

    .section-background {
      height: 120px;
    }

    .vendor-info-container {
      position: relative;
      top: 0;
      margin: 0;
      width: 100%;
      border-radius: 0;
      box-shadow: none;

      .vendor-shop-name {
        min-height: auto;
      }
    }

    .vendor-identity {
      padding: 0 0 30px;

      .btn {
        &.contact-btn {
          min-width: 124px;
        }
      }

      .vendor-logo {
        position: relative;
        margin: -110px 0 25px 0;
        float: none;
        height: 154px;
        width: 154px;
      }
    }

    .share-btn {
      position: absolute;
      top: 20px;
      right: 20px;
      padding: 0;
      background: transparent;

      span {
        height: 52px;
        width: 52px;
        padding: 12px;
        border-radius: 40px;
        border: 1px solid #e8e7e8;
        display: inline-block;
        color: #e8e7e8;
        margin: 0;
        font-size: 24px;
        background: #fff;
      }
    }

    .vendor-address-container {
      border-left: solid 0 #e7e7e7;
      border-top: solid 1px #e7e7e7;
      margin-bottom: 10px;
      padding: 0;

      .opening-title,
      .address-title {
        font-size: 18px;
        margin: 4px 0;

        span {
          font-size: 18px;
        }
      }
    }
  }

  .opening-table-row tr {
    .day {
      width: 30%;
    }
    .hours {
      padding-right: 16px;
      span {
        font-size: 12px;
        font-weight: 500;
      }
    }
  }

  .bloc-editing {
    .add-logo-item {
      .drop-area {
        height: 150px;
      }
    }
  }
}
