.product-img-slider {
    overflow: hidden;
    img {
        width: 100%;
    }
    .pin {
        margin: 12px;
        padding: 4px 8px;
        font-size: 16px;
        font-weight: bold;
    }
}
.product-info {
    .product-naming {
        margin-bottom: $gutter;
        .product-title {
            margin-bottom: 12px;
        }
        .product-seller {
            opacity: 0.4;
            font-weight: 600;
        }
    }
    .product-prices {
        margin-bottom: $gutter/2;
        .product-price {
            @extend .h1;
            color: #5f5aef;
            margin-bottom: 0;
        }
        .product-compare-at-price {
            font-size: 22px;
            text-decoration: line-through;
            color: #ff7889;
        }
        .product-saving {}
    }
}
.product-form {
    margin-top: 16px;
    margin-bottom: 32px;
    .input-number {
        border-radius: 4px;
        width: 100%;
        height: 42px;
    }
    .btn {padding: 11px 12px;}
}

.countdown {
    .alert-information {
        padding: 4px 0;
        margin: 0;
        span {
            background: #5f5aef;
            color: #fff;
            margin: auto;
            display: initial;
            padding: 4px 16px;
            border-radius: 20px;
            font-size: 12px;
            letter-spacing: 0.04em;
            font-weight: bold;
        }
    }
    .counter {
        .time-info {
            text-align: center;
            margin: 0 8px;
        }
        .time-value {
            //display: block;
            margin: 0;
            font-size: 20px;
            font-weight: bold;
        }
        .time-label {
            font-size: 11px;
            font-weight: bold;
            letter-spacing: 0.04em;
        }
        .time-separator {
            border-right: 2px solid #cdd6ed;
            height: 100%;
            display: none;
        }
    }
}
