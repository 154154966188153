.order-breadcrumb {
    .container {
        .content {
            padding: $gutter;

            .text {
                @extend .h2;
                margin: 0;
            }
            .icon {
                font-size: 20px;
                color: #bec4d7;
            }
            .triangle {
                font-size: 11px;
                color: rgba(190, 196, 215, 0.5);
            }

            .text.active,
            .icon.active {
                color: #90e7b4;
            }
        }
    }
}



.order {

    .product {
        margin-bottom: 16px;
    }

    .basket-image {
        @extend .bloc;
        height: 168px;
        width: 168px;
        @include media-breakpoint-down(sm) {
            height: 80px;
            width: 80px;
        }
    }

    .basket-infos {
        margin-left: $gutter/2;
        @include media-breakpoint-down(xs) {
            margin-left: 0;
        }

        .product-name {
            margin-bottom: 0;
        }

        .caption {
            color: #bec4d8;
            font-size: 14px;
            font-weight: 600;
            margin-bottom: 16px;
            display: block;
        }

        .seller-name {
            @include media-breakpoint-down(sm) {
                margin: 0;
            }
        }
    }

    .input-number {
        margin: 0 0 0 $gutter/2;
        @include media-breakpoint-down(xs) {
            margin: 8px 0 0;
        }
    }

    .price {
        font-size: 18px;
        font-weight: 600;
        color: #5f5aef;
        margin: 0;
    }

    .crossed-price {
        font-size: 16px;
        font-weight: 500;
        color: #ff7889;
        margin: 0 16px 0 0;
        text-decoration: line-through;
    }

    .order-summery,
    .info-static {
        padding: $gutter;

        .summer-title {color: #90e7b4;}
        .caption {color: #bec4d7;}

        p {
            font-size: 14px;
            color: #7886af;
            font-weight: 600;
        }

        .total {
            font-size: 18px;
            color: #90e7b4;
            font-weight: 600;
        }

        .btn-green {
            font-style: 16px;
            margin: 0;
        }

        .img-secu {
            min-width: 36px;
            margin-right: 16px;
        }
    }

    .content {
        padding: $gutter;
        margin-bottom: $gutter;
    }

    .dashed {
        border: dashed 2px #bec4d7;
        color: #99a1c3;
        cursor: pointer;
    }

    .order-title {
        font-size: 16px;
        color: #5e53f3;
        font-weight: 600;
    }

    .text-info {
        margin: -2px 0 0 8px;
        font-weight: 600;
        color: #5b6a9a !important;
    }

    .payment-card {
        background: #6775a4;
        border: 2px dashed rgba(95, 89, 239, 0.3);
        position: relative;
        height: 260px;
        width: 390px;
        @include media-breakpoint-down(sm) {
            height: 160px;
            width: 280px;
            margin: 0 0 0 24px;
        }
        display: flex;
        font-size: 16px;
        align-items: center;
        justify-content: center;
        color: #fff;
        border-radius: 16px;
        margin: 0 0 16px 0;

        .card-name {color: #fff;}

        .card-logo {
            position: absolute;
            top: 20px;
            left: 20px;
            width: 54px;
            height: 36px;
            border-radius: 4px;
            background: #FFC107;
        }
        .card-number {
            display: block;
            text-align: center;
            position: absolute;
            color: #fff;
            font-size: 18px;
            font-weight: 600;
            letter-spacing: 0.04em;
            top: 50%;
            transform: translateY(-50%);
        }

        .card-info {
            position: absolute;
            bottom: 20px;
            left: 20px;
            right: 20px;

            p {
                color: #fff;
                font-weight: 600;

                .card-libele {
                    display: block;
                    font-size: 12px;
                    color: #fff;
                }
            }
        }
    }
}
