



.galery-container {
    width: 100%;
    display: grid;
    grid-template-columns: inherit;
    grid-gap: 32px;
    grid-auto-rows: minmax(40px, 160px);
    grid-auto-columns: minmax(40px, 160px);

    .bloc-image {

        &:nth-of-type(1),
        &:nth-of-type(9){
            grid-column: 1 / 2;
            grid-row: 1;
        }

        &:nth-of-type(2),
        &:nth-of-type(10){
            grid-column: 2 / 3;
            grid-row: 1;
        }

        &:nth-of-type(3),
        &:nth-of-type(11){
            grid-column: 1 / 3;
            grid-row: 2 / 4;
        }

        &:nth-of-type(4),
        &:nth-of-type(12){
            grid-column: 3 / 5;
            grid-row: 1 / 3;
        }

        &:nth-of-type(5),
        &:nth-of-type(13){
            grid-column: 3 / 4;
            grid-row: 3;
        }

        &:nth-of-type(6),
        &:nth-of-type(14){
            grid-column: 4 / 5;
            grid-row: 3;
        }

        &:nth-of-type(7),
        &:nth-of-type(15){
            grid-column: 5;
            grid-row: 1;
        }

        &:nth-of-type(8),
        &:nth-of-type(16){
            grid-column: 5;
            grid-row: 2 / 4;
        }
    }
}





//======================
// Galery image pro page
//======================
.grid-galery {
    width: 100% !important;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .gutter-sizer {
        width: 32px;
    }

    .grid-sizer,
    .grid-item {
        width: calc(20% - 26.5px);
    }
    .grid-item {
        height: 168px;
        float: left;
        border-radius: 4px;
        margin-bottom: 32px;

        &:nth-of-type(5),
        &:nth-of-type(7),
        &:nth-of-type(13),
        &:nth-of-type(15) {
            width: calc(40.3% - 26.5px);
            height: 336px;
        }

        &:nth-of-type(10),
        &:nth-of-type(18) {
            width: calc(20% - 26.5px);
            height: 336px;
        }
    }
}



//======================
// Actu galery
//======================
.grid-actu-galery {
    width: 100% !important;

    &:after {
        content: '';
        display: block;
        clear: both;
    }

    .actu-gutter-sizer {
        width: 0%;
    }

    .actu-grid-sizer,
    .actu-grid-item {
        width: 50%;
    }
    .actu-grid-item {
        height: auto;
        float: left;
        border-radius: 4px;
        margin-bottom: 32px;
    }
}

// =========================================
// Medium devices (tablet & landscape phones)
// =========================================


@include media-breakpoint-down(sm) {}




// ========================
// Medium devices  (phones)
// ========================

@include media-breakpoint-down(xs) {}

