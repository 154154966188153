/*===============
     HEADER V2
===============*/


#header {
	z-index: 3;
	position: relative;
	left: 0;
	top: 0;
	width: 100%;

	.navigation {
		box-shadow: 0 2px 4px 0 rgba(74, 144, 226, 0.1);
		background-color: #fff;

		& > .container{height: 80px !important;}

		.header-logo {
			height: 60px;
			min-width: 60px;
			margin: 10px 0 10px 0;

			img {
			    display: block;
			    height: 100%;
			}
		}

		.header-desktop-navigation {}
		.header-mobile-navigation {}

		.header-navigation {
			padding: 0;
			margin: 0;
		    list-style: none;
		    height: 100%;
			li {
				position: relative;
			}
		}

		.navigation-link {
			padding: 20px 0 20px $gutter/2;
			text-decoration: none;
			color: #7a8da3;
		    display: block;
	        font-size: 15px;
		    letter-spacing: -0.02em;
		    font-weight: 500;
			cursor: pointer;

		    &.active {
		    	color: #5f5aef;
		    }
		}

		.navigation-level1 {
			display: block;
			@extend .bloc;
			transform: scale(0, 0);
			transform-origin: top center;
			opacity: 0;
			transition: all 0.3s ease;
			border-top: 4px solid #5f5bea;
			position: absolute;
			right: 0;
			padding: 0;
			background: #fff;
			list-style: none;
			width: 300px;

			&:before {
				content: '';
				position: absolute;
				top: -12px;
				right: 15px;
				width: 0;
				height: 0;
				border-bottom: 9px solid #5d59e9;
				border-right: 8px solid transparent;
				border-left: 8px solid transparent;
			}

			a {
				font-size: 14px;
				color: #5b6a9a;
				font-weight: 500;
				padding: 24px 32px;
				display: block;
				border-bottom: 1px solid #e0e6f0;

				&:hover {
					background: #f4f7fb;
					color: #5f5bea;
				}
			}
		}

		.header-navigation li:hover > .navigation-level1 {
			transform: scale(1, 1);
			opacity: 1;
		}



		//HEADER SEARCH BAR
		.header-search {
			padding: 10px 0;

			.search-bar {
			    position: relative;
			}
			.search-form {
				position: relative;
				.loader {
					display: none;
					@extend .fa;
					position: absolute;
					top: 50%;
					right: 2px;
					-webkit-transform: translateY(-50%);
					transform: translateY(-50%);
					height: 32px;
					width: 32px;
					border-radius: 100px;
					margin: 0;
					text-align: center;
					background: #f6f9fc;
					:after {
						content: "\F110";
						font-family: Font Awesome\ 5 Free, sans-serif;
						font-size: 16px;
						color: #5f59ef;
						position: absolute;
						right: 0;
						top: 0;
						bottom: 0;
						left: 0;
						display: flex;
						justify-content: center;
						align-items: center;
						animation: loader-rotate 1.6s cubic-bezier(0.94, 0.29, 0.51, 0.82) infinite;
					}
				}
			}
			.search {
			    height: 40px;
			    border-radius: 40px;
			    outline: none;
		        width: 45px;
				font-size: 14px;
				padding: 8px 16px;
				background: #f6f9fc;
				border: 1px solid #e0e6f1;
			    box-shadow: none;

			    &:active,
			    &:visited,
			    &:focus,
				&.active{
			    	border: 1px solid #5e59f0;
			    	width: 260px;
		    	    transition: all 0.4s ease;
			    }

			    &::-webkit-input-placeholder {
			    	color: #bec4d7;
			    	font-weight: 500;
			    	font-size: 16px;
			    }
			}
			.fa-search {
		    	position: absolute;
				top: 12px;
				right: 8px;
				color: #bec4d7;
				cursor: pointer;
		    }

		    .fa-times {
			    visibility: visible;
			    position: absolute;
			    top: 1px;
			    right: -5px;
			    background: #f6f9fc;
			    border-radius: 40px;
			    padding: 14px 16px;
			    font-size: 10px;
			    color: #bec4d7;
			}
			.search-results {
				position: absolute;
				display: block;
				border-radius: 4px;
				background-color: #ffffff;
				box-shadow: none;
				min-width: 100%;
				padding: 12px 0;
								right: 0;
				.findPdvBy{
					font-size: 0.8rem;
				}

				.no-result {
					display: block;
					text-align: center;
				}

				.search-result-item {
					display: block;
				    width: 100%;
				    padding: 8px 25px;
                    color: #99a0c4;
                    font-weight: 500;
                    border-bottom: 1px solid #e0e6f1;

				    &:hover {
				    	background: #f3f7fd;
				    	text-decoration: none;
				    }

					img {
						display: inline-block;
						vertical-align: middle;
						width: 32px;
						margin-right: 8px;
						border: 1px solid #e6e7ef;
						border-radius: 4px;
					}
					span {
						display: inline-block;
						vertical-align: middle;
				        font-size: 16px;
						text-align: left;
					}
				}

			}
		}


		//HEADER USER MENU
		.header-usermenu {

			//User menu if logged-in
			.header-user {
				position: relative;
				padding: 19px 0 19px $gutter/2;
				cursor: pointer;

                a {display: flex;}

				.mom-compte {
					background-color: #7a8da3;
					color: white;
					border-radius: 4px;
					padding-left: 5px;
					padding-right: 5px;
				}

                .header-avatar {
                    height: 28px;
					width: 28px;
                    border-radius: 100%;
                }

				.fa-user {
				    font-size: 24px;
				    position: relative;
				    color: #bec4d7;

				    &.active {
				    	color: #5f5aef;
				    }
				}

				.user-popup {
					display: none;
					position: absolute;
					top: 54px;
					right: 0;
					margin: auto;
				    min-width: 280px;
					background: white;
					box-shadow: 0 2px 4px 0 rgba(74, 144, 226, 0.1);
					border: 1px solid #e2e4e8;
					border-radius: 4px;

					&:before {
						content: "";
						position: absolute;
						top: -11px;
						right: 7px;
						width: 0;
						height: 10px;
						border-bottom: 9px solid #e2e4e8;
						border-right: 9px solid transparent;
						border-left: 9px solid transparent;
					}
					&:after {
						content: "";
						position: absolute;
						top: -8px;
						right: 8px;
						width: 0;
						height: 8px;
						border-bottom: 8px solid #fff;
						border-right: 8px solid transparent;
						border-left: 8px solid transparent;
					}

					.container {
						padding: 0;
					}

					.separator {
					    font-weight: 800;
					    font-size: 10px;
                        text-transform: uppercase;
                        padding: 16px 0 4px;
                        margin: 16px 24px 8px;
                        color: #bec4d8;
                        border-bottom: 1px solid #bec4d8;

						&.no-text {
							padding: 0 0 0;
							margin: 4px 24px 4px;
						}
					}
				}

				.profile {
				    display: block;
    				padding: 20px 25px 8px;

					.avatar {
						width: 40px;
						border-radius: 40px;
						margin-right: 8px;
						display: inline-block;
					    vertical-align: middle;
					}
					.name{
						font-weight: 600;
					    font-size: 14px;
					    color: #5b6a9a;
					    display: inline-block;
					    vertical-align: middle;
					}
				}

				.profile-link {
					display: block;
				    font-size: 14px;
				    width: 100%;
				    padding: 4px 25px;
				    color: #1e334a;
				    text-decoration: none;
				    font-weight: 500;

				    &:hover {
					    background: #f6f9fc;
				    }

				    &.logout {
				    	color: #ff7889;
				    }

				    &:first-of-type {
				    	margin-top: 16px;
				    }
				    &:last-of-type {
				    	margin-bottom: 16px;
				    }
				}
			}

			//User login form
			.header-login {
				width: 320px;

				h3 {
					padding: 20px 25px 0;
					text-align: center;
					color: #5e59f0;
					font-weight: 600;
					font-size: 24px;
				}
			}
		}


		//HEADER BASKET
		.header-basket {
			display: flex;
			position: relative;
			padding: 19px 0 19px $gutter/2;
			cursor: pointer;

			.basket-icon {
			    font-size: 24px;
			    position: relative;
			    color: #bec4d7;
				margin: 0;

			    &.active {color:#5f5aef;}

			    .pin {
					position: absolute;
					top: -1px;
					right: -4px;
					background: #ff7889;
					border-radius: 40px;
					z-index: 1;
					padding: 2px 4px;
					color: #fff;
					font-size: 10px;
			    }
			}

			.empty-basket-text {
				display: block;
			    text-align: center;
			    margin: 32px 0;
			}
			.basket-bottom {
				display: none;
			    margin: 8px 0 0 0;
			    padding: 16px 0 0 0;
			    border-top: 1px solid #e7e7e7;

			    .basket-total-price {
		    	    padding-bottom: 16px;

		    	    span {
		    	    	font-size: 14px;
					    font-weight: 600;
					    color: #5e59f0;
		    	    }
				}
			}

			&.active {
				.basket-icon {color:#5f5aef;
					.pin {display: block;}
				}

				.empty-basket-text {display: none;}
				.basket-bottom {display: block;}
			}

			.basket-summary {
				display: none;
				position: absolute;
				top: 54px;
				right: 0;
				margin: auto;
				width: 315px;
				background: white;
				box-shadow: 0 2px 4px 0 rgba(74, 144, 226, 0.1);
				border: 1px solid #e2e4e8;
				border-radius: 4px;

				&:before {
					content: "";
					position: absolute;
					top: -11px;
					right: 7px;
					width: 0;
					height: 10px;
					border-bottom: 9px solid #e2e4e8;
					border-right: 9px solid transparent;
					border-left: 9px solid transparent;
				}
				&:after {
					content: "";
					position: absolute;
					top: -8px;
					right: 8px;
					width: 0;
					height: 8px;
					border-bottom: 8px solid #fff;
					border-right: 8px solid transparent;
					border-left: 8px solid transparent;
				}

				.container {
					padding: 20px 25px 25px;
				}
			}

			.product-row {
				padding: 4px 0;

				.product-img {
				    width: 56px;
				    height: 56px;
				    border-radius: 4px;
				    margin: 0 8px 0 0;
				}

				.product-info {
					.name {
						display: block;
						font-size: 16px;
						font-weight: 500;
						line-height: 20px;
					}
					.qty {
						display: block;
						font-size: 12px;
						line-height: 14px;
					}
					.price {
						display: block;
						font-size: 14px;
						color: #5f5aef;
						font-weight: 600;
					}
				}

				.delete-product {
					padding: 10px 0;
					width: 24px;
					margin-left: -6px;
				}
			}
			.go-to-basket {
				width: 100%;
				height: 38px;
				border-radius: 40px;
				background-color: #5f5aef;
				font-size: 16px;
				font-weight: 600;
				letter-spacing: 0.02em;
				line-height: 18px;
			}
		}
	}
}
// Sélecteur de clients
#select_client {
	width: 300px;

	&.vs--open {
		.vs__selected {
			height: 100%;
		}
	}
	.vs__dropdown-toggle {
		height: 40px;
		padding: 0;
		border-color: #7A8DA3;
		border-radius: 10px;
	}
	.vs__selected {
		margin: 0;
		padding: 0 10px;
	}
	.vs__search {
		padding: 0 10px;
		font-size: 12px;
		font-weight: 600;
		color: #7A8DA3;
	}
	.vs__actions {
		margin: 5px 0;
		padding: 0 10px;
		border-left: 1px solid #7A8DA3;
	}
	.vs__clear {
		display: none;
	}
}
// Sélecteur comme les input "form-control"
.as-form-control {
	.vs__dropdown-toggle {
		height: 42px;
		border-color: #EBEDF2;
	}
}
.vs__dropdown-option, .vs__selected-options {
	.option-client-in {
		display: flex;
		height: 100%;
		align-items: center;
		font-size: 12px;
		line-height: 12px;
		font-weight: 600;
		color: #7A8DA3;
	}
	.icon-circle {
		display: flex;
		width: 25px;
		height: 25px;
		justify-content: center;
		align-items: center;
		border-radius: 25px;

		&.admin {
			background-color: #F2C111;
		}
		&.editeur {
			background-color: #5F5AEF;
		}
		&.lecteur {
			background-color: #00CFC4;
		}
		i {
			font-size: 12px;
			color: white;
		}
	}
}
.vs__dropdown-menu {
	.vs__dropdown-option {
		height: 40px;
		padding: 3px 10px;
	}
	.vs__dropdown-option--highlight {
		.option-client-in {
			color: white;
		}
	}
	.vs__no-options {
		font-size: 12px;
		font-weight: 600;
		color: #7A8DA3;
	}
}

//OPEN & CLOSE HEADER POPUP
#body.basket-active {
	#header {
		.basket-summary {
			display: block;
		}
	}
}
#body.profile-active {
	#header {
		.user-popup {
			overflow-y: auto;
			display: block;
		}
	}
}


//==========================
// Medium devices from 768px
//==========================

@include media-breakpoint-down(sm) {
	.navigation {
		.header-logo{
			img{
				margin-left: auto;
				margin-right: auto;
			}
		}

		.header-mobil-navigation {

			.burger-open-menu,
			.burger-close-menu {

				margin-left: -16px;
				padding: 16px;
				display: block;
				height: 60px;
				width: 60px;
				text-align: center;

				.fas {
					font-size: 27px;
					margin: 0 auto;
					text-align: center;
					display: inline-block;
				}
			}
			.burger-close-menu {
				padding: 16px 16px 16px 16px;
				margin-left: 0;
				display: none;
			}

			.mobil-nav-container {
				position: absolute;
				list-style: none;
				background: #f0f5fc;
				top: -1000px;
				left: 0;
				width: 100%;
				height: 0;
				opacity: 0;
				z-index: 11;
				padding: 16px;
				-webkit-transition: transform 0s, opacity 0.2s ease, height 0.6s cubic-bezier(0.27, 0.74, 0.01, 1.2);
				-moz-transition: transform 0s, opacity 0.2s ease, height 0.6s cubic-bezier(0.27, 0.74, 0.01, 1.2);
				-ms-transition: transform 0s, opacity 0.2s ease, height 0.6s cubic-bezier(0.27, 0.74, 0.01, 1.2);
				-o-transition: transform 0s, opacity 0.2s ease, height 0.6s cubic-bezier(0.27, 0.74, 0.01, 1.2);
				transition: transform 0s, opacity 0.2s ease, height 0.6s cubic-bezier(0.27, 0.74, 0.01, 1.2);

			}
		}
	}

	#header {
		.navigation {

			& > .container{height: 60px !important;}

			.header-logo {
				margin: auto;
			}

			//HEADER SEARCH BAR
			.header-search {

				.search-bar {
					position: fixed;
				    top: -200px;
				    left: 0;
				    width: 100%;
				    background: #fff;
				    height: 60px;
				    padding: 10px;
				    z-index: 1111;
				    transition: all 0.8s cubic-bezier(0.69, 0.03, 0.43, 1.18);
				}

				.fa-search {
				    right: initial;
				    left: 12px;
				}

				.fa-times {
				    padding: 8px 12px;
				    font-size: 22px;
				    visibility: visible !important;
				}

				.search {
					border: 1px solid #5e59f0;
					height: 40px;
					border-radius: 40px;
					padding: 8px 16px 8px 40px;
					width: 100%;
                    &.active,
                    &:active,
				    &:visited,
				    &:focus {
				    	width: 100%;
				    }
				}

				.search-results {
				    border-radius: 0;
				    box-shadow: none;
				    left: 0;
				    top: 60px;
				}
			}

			.show-search {
			    height: 40px;
			    width: 48px;
			    background: #f0f5fc;
			    position: relative;
			    border-radius: 30px;
			    text-align: center;
		        padding: 10px;
    			margin: 10px 0;

				.fa-search {
				    right: 0;
				    left: 0;
				    margin: auto;
				}
			}

			.navigation-link {
    			font-size: 26px;
    			font-weight: 400;
    			//text-align: center;
				border-bottom: 1px solid #b0bccb;
				cursor: pointer;
				opacity: 0;
			    transform: translateY(0px);
			    transition: transform cubic-bezier(0, 0.04, 0.1, 0.96) 0.6s, opacity 0.3s ease;
			}

			.navigation-link-2 {
				padding: 16px;
				text-decoration: none;
				color: #1e3349;
				display: block;
				font-size: 16px;
				letter-spacing: -0.02em;
				font-weight: 500;
				padding-left: 24px;
			}

			//HEADER USER MENU
			.header-usermenu {
				.header-user {
					.user-popup{
						left: 0;
						padding-bottom: 50px;
						top: 60px;
						width: 100%;
						position: fixed;
						height: calc(100vh - 60px);
						border: none;
						&:before,
						&:after {
							display: none;
						}
					}
					.avatar {
					    width: 60px;
    					border-radius: 60px;
					}
					.name {
						font-size: 18px;
					}
					.profile-link {
						font-size: 20px;
						padding: 12px 24px;
					}
				}
			}
		}
	}


	#body.menu-active {

		.mobil-nav-container {
			top: 0;
			opacity: 1;
			height: 100vh;
			padding :0 0 20px 0;
			overflow: auto;

			.navigation-link {
			    opacity: 1;
			    transform: translateY(8px);
			    transition: transform cubic-bezier(0, 0.04, 0.1, 0.96) 0.8s, opacity 0.6s ease;
			}

			li:nth-of-type(1) .navigation-link {transition-delay: 0s;}
			li:nth-of-type(2) .navigation-link {transition-delay: 0.04s;}
			li:nth-of-type(3) .navigation-link {transition-delay: 0.08s;}
			li:nth-of-type(4) .navigation-link {transition-delay: 0.12s;}
			li:nth-of-type(5) .navigation-link {transition-delay: 0.16s;}
			li:nth-of-type(6) .navigation-link {transition-delay: 0.2s;}
			li:nth-of-type(7) .navigation-link {transition-delay: 0.24s;}
			li:nth-of-type(8) .navigation-link {transition-delay: 0.28s;}
		}

		.burger-close-menu {
			display: block;
		}
	}

	#body.search-active {
		#header {
			.navigation {
				.header-search {
					.search-bar {
						top: 0;
					}
				}
			}
		}
	}
}



