
.product-list {
    margin: $gutter/2 0;
}
.product-card {
    overflow: hidden;
    margin-bottom: $gutter;

    & > a {
        display: block;
        margin: 0;
        padding: 0;
    }

    .product-img {
        height: 268px;
    }

    .card-core {
        padding: 16px;

        .card-vendor {
            color: #bec4d7;
            font-size: 12px;
            span {
                color: #5f5aef;
                font-size: 12px;
            }
        }

        h2 {
            margin: 0 0 8px 0;
        }
    }

    .card-bottom {
        padding: 20px 16px;
        border-radius: 0 0 4px 4px;
        position: relative;
        overflow: hidden;

        &:before {
            position: absolute;
            content: 'Ajouter au panier';
            background: #5f5aef;
            overflow: hidden;
            left: -50%;
            opacity: 0;
            top: 0;
            width: 100%;
            height: 100%;
            transition: all 0.3s ease;
            color: #bec4d7;
            text-align: center;
            padding: 21px 0 0 10px;
            font-weight: 600;
            font-size: 18px;
            cursor: pointer;
        }

        &:hover:before {
            width: 100%;
            left: 0;
            opacity: 1;
            transition: all 0.3s ease;
        }

        .add-to-cart {
            font-size: 24px;
            position: relative;
            color: #bec4d7;
            margin: 0;
        }

        .card-price-crossed {
            color: #bec4d7;
            font-size: 12px;
            line-height: 14px;
            font-weight: 600;
            text-decoration: line-through;
        }

        .card-price {
            color: #5f5aef;
            font-size: 23px;
            line-height: 18px;
            font-weight: 600;
            margin-left: 8px;
        }
    }

}

.select2-tag .select2.select2-container{
    border: solid #734cea 1px;
    height: auto !important;
    min-height: 42px;
    border-radius: 4px;
    padding: .37rem 0.5rem;
    .container-tag-select2{
        border: none;
        ul.select2-selection__rendered{
            transform: none;
            display: inline;
        }
    }
}