.user-profile-orders {
    @media (min-width: 440px) {
        max-width: 767px !important;
    }
    @media (min-width: 769px) {
        max-width: 960px !important;
    }
    @media (min-width: 1024px) {
        max-width: 1024px !important;
    }
    @media (min-width: 1440px) {
        max-width: 1240px !important;
    }
}

.user-profile {

    .page-title {
        color: #37385e;
        margin: 0;
    }

    @include media-breakpoint-down(sm) {
        // .sticky-nav {
        //     //display: none !important;
        // }
        .sticky-twin {
            .sub-nav {
                padding: 0;
               // position: fixed;
                bottom: 0;
                left: 0;
                right: 0;
                width: 100%;
                // z-index: 998;
               // box-shadow: 0 -6px 24px 0 rgba(74, 144, 226, 0.32);
            }
        }

        .sub-nav {
            padding: 0;
            .tab {
                span {
                    display: block;
                    text-align: center;
                    font-size: 7px;
                    text-transform: uppercase;
                    margin: 0;
                    letter-spacing: 0.04em;
                }

                &.active:after {
                    display: none;
                }
            }
        }
    }

    h3 {
        font-size: 18px;
        font-weight: 600;
        color: #37385e;
        margin-bottom: $gutter/2;
        @include media-breakpoint-down(sm) {
            font-size: 16px;
        }
    }

    h4 {
        font-size: 12px;
        font-weight: 600;
        color: #5f5aef;
        color: #bec4d8;
        color: #7a8ca3;
        margin-bottom: $gutter;
        letter-spacing: 0.04em;
        text-transform: uppercase;
        @include media-breakpoint-down(sm) {
            font-size: 11px;
            margin: $gutter/4 0 $gutter/4*3;
        }
    }

    .bloc-phone,
    .bloc-email,
    .bloc-address {
        padding: $gutter;
        @include media-breakpoint-down(sm) {
            padding: $gutter/2;
        }
    }


    .phone,
    .email,
    .address {
        margin-bottom: 12px;
        font-weight: 600;
        @include media-breakpoint-down(sm) {
            font-size: 12px;
        }
    }

    .profile-logout {
        display: inline-block;
        color: #ff7787;
        font-size: 16px;
        font-weight: 600;
        margin: 0 0 4px 0;
        i {
            transform: rotate(180deg);
            font-size: 15.5px;
            margin: 0 4px 0 2px;
        }
    }

    .bloc-info {
        padding: $gutter;
        @include media-breakpoint-down(sm) {
            padding: $gutter/2;
        }

        .profile-avatar {
            position: relative;
            border-radius: 4px;
            overflow: hidden;
            display: flex;
            width: 64px;
            background: #4e5c73;

            .avatar {
                width: 64px;
                height: 64px;
            }
        }

        .profile-info {
            padding: 0 0 0 $gutter/2;
            width: calc(100% - 64px);

            .user-name {
                font-weight: 500;
                color: #37375f;
                margin-bottom: 8px;
                font-size: 24px;
                text-transform: initial;
                letter-spacing: 0;
                @include media-breakpoint-down(sm) {
                    font-size: 18px;
                }
            }
            .birthday-date {
                margin: 0;
                color: #7a8da3;
            }
        }
    }


    .edit-logo {
        width: 140px;
        height: 140px;
        margin: 12px 18px;
        border-radius: 4px;
        @include media-breakpoint-down(sm) {
            margin: 10px auto;
            border-radius: 4px;
        }
    }


    .add-logo-item {
        width: 140px;
        height: 140px;
        margin: 0;
        border-radius: 4px;
        @include media-breakpoint-down(sm) {
            margin: 0 auto;
            border-radius: 4px;
        }
    }

    .drop-area {
        width: 100%;
        height: 140px;
        display: none;
        @include media-breakpoint-down(sm) {
            width: 80px !important;
            height: 80px !important;
        }
    }

    .table.bloc {
        overflow: hidden;
        margin-bottom: $gutter;
    }
    .table .table-header {
        font-weight: 600;
        padding: 8px 0;
        color: #37385e;
        background: #dee2e6;
        background: #e0e6f0;
    }


    .add-btn {
        text-transform: initial;
        font-weight: 800;
        color: #00cfc4;
        border: 1px solid #00cfc4;
        float: right;
        cursor: pointer;
        border-radius: 22px;
        padding: 4px 8px;
        font-size: 12px;
        margin-top: -3px;
        @include media-breakpoint-down(sm) {
            font-size: 10px;
            margin-top: -5px;
        }

        i {
            vertical-align: baseline;
            font-size: 13px;
            display: none;
        }

        &:hover,
        &:active {
            color: #f4f7fb;
            background: #00cfc4;
        }
    }

    .pme-section,
    .fidelity-section {

        .pme-bloc,
        .fidelity-bloc {
            .element {
                padding: $gutter;
                @include media-breakpoint-down(sm) {
                    padding: $gutter/2;
                }
                border-bottom: 1px solid #e0e6f1;
                // &:first-of-type {
                //     //padding-top: $gutter;
                // }
                &:last-of-type {
                    border-bottom: 0 solid #e0e6f1;
                    //padding-bottom: $gutter;
                }
            }
            .content {
                @include media-breakpoint-down(sm) {
                    width: 100%;
                }
            }
            img {
                border-radius: 4px;
                width: 80px;
                @include media-breakpoint-down(sm) {}
                margin-right: $gutter/2;
                width: 48px;
            }
            .commerce-name {
                color: #37385e;
                font-weight: 800;
                text-transform: capitalize;
                margin: 0;
            }
        }

        .pie-wrapper {
            font-size: 80px;
            @include media-breakpoint-down(sm) {
                font-size: 40px;
            }
            .label {
                font-weight: bold;
                color: #5a699c;
            }
            .half-circle {
                border: 8px solid #5e53f3;
                transition: all 1s cubic-bezier(0.92, 0.18, 0.46, 1.28);
            }
            .shadow {
                border: 8px solid #e0e6f1;
            }
        }
    }

    .bloc-documents {
        padding: $gutter/2 $gutter;
        @include media-breakpoint-down(md) {
            padding: 0;
        }

        .id-card,
        .passport,
        .driver-licence,
        .justif-dom,
        .justif-dom2,
        .justif-demande_chko,
        .taxe-hab,
        .avis-impos,
        .kbis,
        .dads {
            @extend .bb;
            padding: $gutter/2;

            .icon {
                font-size: 32px;
                color: #00d0c5;
                margin: 0 16px 0 0;
                @include media-breakpoint-down(sm) {
                    font-size: 22px;
                    margin: 0 8px 0 0;
                }
            }

            .loaded-doc {
                display: flex;
                .icon {
                    color: #00d0c5;
                    display: block;
                }
            }

            .actions {
                @extend .ml-auto;
                max-width: 140px;
                @include media-breakpoint-down(sm) {
                    padding: 4px 8px;
                    width: auto;
                    font-size: 10px;
                }
            }
        }

        .dads {border: none;}

        //TODO Fix en attendant le changement des boutons bloc-editing
        .bloc-editing.fixed {
            display: flex;
        }
    }
}

.user-card {
    display: table;
    position: relative;
    width: 100%;
    height: 280px;
    @include media-breakpoint-down(sm) {
        height: 175px;
        width: 100%;
        border-radius: 8px;
    }
    background: #6775a4;
    background: -moz-linear-gradient(-45deg, #433e85 5%, #3f4d99 86%);
    background: -webkit-linear-gradient(-45deg, #433e85 5%,#3f4d99 86%);
    background: linear-gradient(135deg, #433e85 5%,#3f4d99 86%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#433e85', endColorstr='#3f4d99',GradientType=1 );
    border-radius: 16px;
    padding: 24px;
    margin: 0 auto 24px;
}


.payment-card {
    border: 1px solid #dfdfe642;
    text-shadow: -1px 1px 0px #00000080;
    box-shadow: -1px 1px 0px 0px #9091a5, 4px -4px 20px 0px #00000047;
    background: -moz-linear-gradient(135deg, #2f9679 5%, #3f4d99 86%);
    background: -webkit-linear-gradient(-45deg, #2f9679 5%, #3f4d99 86%);
    background: linear-gradient(135deg, #2f9679 5%, #3f4d99 86%);
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#2f9679', endColorstr='#3f4d99',GradientType=1 );

    .payment-card-header {
        position: absolute;
        top: 20px;
        right: 24px;
        left: 24px;
        height: 24px;
    }
    .payment-card-body {
        position: absolute;
        top: 50%;
        right: 24px;
        left: 24px;
        transform: translateY(-50%);
    }
    .payment-card-footer{
        position: absolute;
        right: 24px;
        left: 24px;
        bottom: 20px;
        margin: auto;
        text-align: center;
    }

    .card-name {
        text-shadow: -1px 1px 0px #00000080;
        color: #fff;
    }

    .card-logo {
        display: block;
        width: 64px;
        @include media-breakpoint-down(sm) {
            width: 48px;
        }
    }
    .card-number {
        display: block;
        text-align: center;
        color: #fff;
        font-size: 18px;
        font-weight: 600;
        letter-spacing: 0.08em;
        text-shadow: -1px 1px 0px #00000080;
    }

    .card-info {
        p {
            color: #fff;
            font-weight: 600;
            margin-bottom: 0;
            text-shadow: -1px 1px 0px #00000080;

            .card-libele {
                display: block;
                font-size: 12px;
                color: #fff;
                text-shadow: -1px 1px 0px #00000080;
            }
        }
    }
}


.cart-section {
    width: 460px !important;
    position: relative;
    margin: 40px 40px 0 40px;
    padding: 0;
    outline: none;
    cursor: grab;
    @include media-breakpoint-down(sm) {
        width: 290px !important;
        height: 175px;
        margin: 16px 10px 8px;
    }
    &:mousedown {
        cursor: grabbing;
    }

}

.fid-card:nth-child(n) {
    display: none;
}


.fid-card:nth-child(1) {
    position: absolute;
    display: block !important;
    opacity: 0.5;
    transform: scale(0.855) rotate(0deg) translate(0px,-49px);
    @media (max-width: 768.98px) {transform: scale(0.82) rotate(0deg) translate(0px, -39px);}
    background: #b4bde2;
    box-shadow: none;
}

.fid-card:nth-child(2) {
    position: absolute;
    display: block !important;
    background: #aab0da;
    opacity: 1;
    transform: scale(0.94) rotate(0deg) translate(0px,-20px);
    @media (max-width: 768.98px) {transform: scale(0.92) rotate(0deg) translate(0px, -16px)}
    box-shadow: none;
}

.fid-card:last-child {
    position: absolute;
    display: block !important;
    background: #f6F6F6;
    top: 0;
    opacity: 1;
    transform: scale(1);
    border: 1px solid #dfdfe642;
    text-shadow: -1px 1px 0px #00000080;
    box-shadow: -1px 1px 0px 0px #9091a5, 4px -4px 20px 0px #00000047;
    background: linear-gradient(135deg, #433e85 5%, #3f4d99 86%);
}
.fid-card {
    @media (max-width: 768.98px) {
        height: 100%;
        width: 100%;
        border-radius: 8px;
    }


    .fid-card-header {
        position: absolute;
        top: 20px;
        right: 24px;
        left: 24px;
        height: 24px;
        @include media-breakpoint-down(sm) {
            top: 8px;
            right: 8px;
            left: 8px;
        }
    }
    .fid-card-body {
        position: absolute;
        top: 40%;
        right: 24px;
        left: 24px;
        transform: translateY(-50%);
        @include media-breakpoint-down(sm) {
            top: 45%;
        }
    }
    .fid-card-footer{
        position: absolute;
        right: 24px;
        left: 24px;
        bottom: 20px;
        margin: auto;
        text-align: center;
        @include media-breakpoint-down(sm) {
            right: 0;
            left: 0;
            bottom: 0;
        }
    }

    .logo {
        width: 60px;
        border-radius: 12px;
    }

    .card-name {
        text-align: center;
        color: #ffffff;
        text-shadow: -1px 1px 0px #00000080;
        b {
            font-weight: bolder;
            color: #ffffff;
            font-size: 18px;
            color: #fff;
            letter-spacing: 0.08em;
            text-shadow: -1px 1px 0px #00000080;
            @include media-breakpoint-down(sm) {
                font-size: 14px;
                letter-spacing: 0.14em;
            }
        }
    }


    .barcode {
        border-radius: 6px;
        @include media-breakpoint-down(sm) {
            height: 60px;
            max-width: calc(100% - 16px);
        }
    }
}

.cashless-header .dropdown .marker{
    font-size: 14px;
}
.card-placeholder,
.card-loader {
    position: initial;
    display: table-cell;
    vertical-align: middle;
    text-align: center;

    .loader {
        position: relative;
        margin: 0 auto;
        display: block;
        height: 24px;
        width: 24px;
        &:after,
        .marker {
            color: #5d4bf7;
        }
    }

    .btn {
        width: auto;
        display: inline-block;
        padding-left: 24px;
        padding-right: 24px;
    }
}

.adding-card {
    display: none;
    border-radius: 16px;
    margin-bottom: 48px;
}

.slider-fid-nav,
.slider-credit-nav {
    .prev {
        transform: rotate(180deg);
        border: none;
        outline: none;
        color: #5e54f3;
    }
    .next {
        border: none;
        outline: none;
        color: #5e54f3;
    }
}
.cashless-header .dropdown-item{
    font-size: 10px;
}
.cashless-header .numCarte{
    font-weight: 500;
}
.card-fid-slider,
.card-credit-slider {
    .slick-dots {
        margin: auto;
        align-items: center;
        justify-content: center;
        height: 40px;
        li {
            font-size: 8px;
            margin: 8px 4px 0;
        }
    }
}

.cashless-section {

    .cashless-bloc {margin-bottom: $gutter/2;}

    .cashless-bloc .placeholder {
        border: solid 1px rgba(95, 89, 239, 0.2);
        background: #e0e6f070 !important;
        -webkit-box-shadow: none;
        box-shadow: none;
        padding: 16px;
    }

    .cashless-ticket-icon {
        color: #bec4d799;
        margin: $gutter/2 $gutter/2 $gutter/2 0;
        font-size: 40px;
    }

    .cashless-placeholder-btn {
        font-size: 12px;
        padding: 8px 12px;
        margin: 0 40px;
        @include media-breakpoint-down(sm) {
            display: none;
        }
    }

    .cashless-content {
        padding: $gutter/4*3 $gutter;
        @include media-breakpoint-down(sm) {
            padding: $gutter/4*3 $gutter/2;
        }
    }
    .cashless-header {
        @extend .justify-content-between;
        @include media-breakpoint-down(sm) {
            align-items: center;
            position: relative;
            justify-content: start;
        }
    }
    .cashless-libele {
        max-width: 220px;
        @include media-breakpoint-down(sm) {
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
        }
        h3 {
            margin: 0;
            //margin-right: 36px; mis en commentaire car probleme quand billet affiché
            line-height: inherit;
            @include media-breakpoint-down(sm) {
                width: 95%;
                white-space: nowrap;
                text-overflow: ellipsis;
                overflow: hidden;
            }
        }
    }
    .sold {
        span {
            font-size: 24px;
            color: #00cfc4;
            font-weight: bold;
            @include media-breakpoint-down(sm) {
                display: block;
                font-size: 17px;
            }
        }
    }
    .update {
        text-transform: initial;
        @include media-breakpoint-down(sm)  {
            bottom: 0;
            right: 0;
            left: 0;
            font-size: 8px;
        }
    }
    .open-info {
        cursor: pointer;
        @include media-breakpoint-down(sm) {
            //border: 1px solid #5e54f3;
            border-radius: 4px;
            font-size: 10px;
            margin: 0;
       }
        cursor: pointer;
        //@extend .ml-auto;
        span {
            // font-size: 11px;
            // color: #5e54f3;
            // font-weight: 600;
            // margin-right: 4px;
            @include media-breakpoint-down(sm) {
                font-size: 10px;
            }

        }
        i {
            font-size: 5px;
            color: #5e54f3;
            font-weight: 600;
            margin: 0;
            transform: rotate(90deg);
            transition: all 0.3s ease;
        }
        &.closed i {transform: rotate(0deg);}
    }
    .cashless-detail {
        padding-top: 24px;
        -webkit-animation-duration: 250ms;
        animation-duration: 250ms;

        .table {
            box-shadow: inset 0 0 0 1px #e0e6f0;
            border-radius: 3px;
            overflow: hidden;
        }
    }
}

.row-credit {
    .amount {
        color: #00cfc4;
        span {
            position: relative;
            &:after {
                content: '+';
                color: #00cfc4;
                font-weight: bold;
            }
        }
    }
}
.row-debit {
    .amount {
        color: #ff7889;
        span {
            position: relative;
            &:after {
                content: '-';
                color: #ff7889;
                font-weight: bold;
            }
        }
    }
}

.cashless-action {
    @include media-breakpoint-down(sm) {
        display: flex;
        margin: 16px 0 0;
    }
}
.cashless-btn {
    font-size: 11px;
    width: auto;
    padding: 8px 4px;
    display: block;
    @include media-breakpoint-down(sm) {
        margin: 0;
        display: inline-block;
        padding: 4px;
        font-size: 10px;
    }
}


.bloc-rib {
    padding: 32px 24px;

    .rib-content {
        padding:16px 16px 8px;
        @extend .bt, .br;
        &:nth-of-type(2) {border-top: none;border-right:none;}
        &:nth-of-type(2n+0) {border-right: none;}
        &:nth-of-type(1) {border-top: none;}

        @include media-breakpoint-down(sm) {
            border-radius: 0;
            border-left: none;
            border-right: none;
            border-top: 1px solid  #e7ecf3;
            &:nth-of-type(1) {border: none;}
            // &:nth-of-type(2) {}
        }
    }
}

.checkout-title {
    margin: 8px 0 32px;
    text-align: center;
}

.checkout-card {
    padding: $gutter/4*3;
    margin-bottom: $gutter/4*3;
    @include media-breakpoint-down(sm) {
        padding: $gutter/2;
        margin-bottom: $gutter/2;
    }

    .custom-checkbox input:checked ~ .checkmark {
        background-color: #5f5aef;
        border: 1px solid #5f5aef;
    }
    .custom-checkbox .checkmark {
        border-radius: 3px !important;
    }

    label {
        margin: 0;
    }

    .credit-card-content {
        width: 100%;
        margin-left: 24px;

        .card-logo svg {
            @include media-breakpoint-down(sm) {
                width: 36px;
            }
        }

        .card-number {
            margin-left: 24px;
            .card-name {
                margin: 0;
                font-weight: bold;
                font-size: 15px;
            }
        }

        .card-holder {
            p {
                margin: 0;
            }
        }
    }

    .new-card-content {
        width: 100%;
        margin-left: 24px;
        .text {
            margin: 0 16px 0 0;
            font-size: 15px;
            font-weight: bold;
        }
        .card-logo svg {
            margin-left: 16px;
            width: 36px;
        }
    }
}

.card-status {
    position: absolute;
    top: 4px;
    right: 8px;
    font-size: 11px;
    font-weight: bold;
    &.active {
        color: #00cfc4;
        .circle {
            background: #00cfc4;
        }
    }
    &.expired {
        color: #ff7889;
        .circle {
            background: #ff7889;}
        }
    }
    .circle {
        height: 6px;
        width: 6px;
        border-radius: 6px;
        display: inline-block;
}
.under-table {
  & > td {
    padding: 0;
    border: none;
    background: #e0e6f0;
  }

  thead th {
    font-size: 10px !important;
    text-transform: uppercase;
  }

  tbody tr {
    background: #e0e6f0;
  }
}

.table .table {
  background-color: #e0e6f0;
}

.view-more {
  display: inline-block;
  font-size: 12px;
  font-weight: bold;
  color: #5f5aef;
  float: right;
  cursor: pointer;

  span {
    font-size: 10px;
  }
}

.validation-cashless {
    padding: $gutter $gutter/4*3;
    text-align: center;
    margin: 32px auto;
    max-width: 500px;
    @include media-breakpoint-down(sm) {
        padding: $gutter/4*3 $gutter/2;
    }

    .btn {
        max-width: 300px;
        margin: auto;
        display: block;
        white-space: pre-wrap;
    }

    &.success {
        .check,
        i {
            font-size: 32px;
            color: #00cfc4;
        }
    }
    &.error {
        h1 {
            color: #ff7889;
        }
        .check,
        i {
            font-size: 32px;
            color: #ff7889;
        }
    }
}

.rib-content {
    //border: 1px solid #bec4d7;
    padding: $gutter/2;
    border-radius: 4px;
    margin-bottom: $gutter;

    .custom-checkbox {
        margin-right: $gutter/2;
    }
    .custom-checkbox input:checked ~ .checkmark {
        background-color: #5f5aef;
        border: 1px solid #5f5aef;
    }
    .custom-checkbox .checkmark {
        border-radius: 3px !important;
    }

    label {
        margin: 0;
    }
}

.form-ajout-rib {
    margin-top: $gutter/4*3;
/*
    label.inline-style {
        display: inline-block;
        height: 42px;
        margin: 0;
        vertical-align: middle;
        padding: 12px;
        background: #bec4d7;
        margin-right: -4px;
        border-radius: 4px 0 0 4px;
        color: #fff;
        font-weight: bold;
        min-width: 60px;
        text-align: center;
    }
    input.inline-style {
        display: inline-block;
        width: auto;
        vertical-align: middle;
        border-radius: 0 4px 4px 0;
        outline: none;

        &:focus {
            width: auto;
        }
    }
*/
}

.user-manager-tab {
    &__title {
        display: flex;
        align-items: center;

        h3 {
            margin-right: 20px;
            margin-bottom: 0;
        }
    }
    .vgt-table {
        thead {
            th {
                font-size: 14px;
            }
        }
    }
}
