.sub-nav {
    padding: $gutter $gutter/2;
    @include media-breakpoint-down(sm) {
        white-space: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch;
        -ms-overflow-style: -ms-autohiding-scrollbar;
        margin: 0 -$gutter/2;
        padding: $gutter/2 0;
        &::-webkit-scrollbar {
            display: none;
        }
    }
    .tab {
        position: relative;
        padding: $gutter/2;
        cursor: pointer;
        font-size: 16px;
        color: #37385e;
        font-weight: 500;
        text-align: center;
        @include media-breakpoint-down(sm) {
            padding: $gutter/4 $gutter/2;
            font-size: 14px;
        }

        &:hover {opacity: 0.8;}

        &.active {
            color: #5f5aef;
            font-weight: 600;

            svg, svg g, svg path {
                fill: #5f5aef;
                stroke: #5f5aef;
            }

            &:after {
                content: '';
                position: absolute;
                background: rgba(95, 90, 239, 1);
                left: 50%;
                -webkit-transform: translateX(-50%);
                transform: translateX(-50%);
                width: 6px;
                height: 6px;
                bottom: 6px;
                border-radius: 4px;
                bottom: 0;
            }
        }
        a {color: inherit;}

        svg {
            height: 20px;
            width: 22px;
        }
    }
}


.sticky-twin {
    .sub-nav {
        position: relative;
        border-radius: 4px;
        padding: $gutter $gutter/2;
        background-color: #ffffff;
        border: 1px solid #e0e6f1;
        -webkit-box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1);
        box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1);
    }
    @include media-breakpoint-down(sm) {
        .container {
            padding: 0 0 $gutter/2 0;

            .sub-nav {
                border-radius: 0;
                border: none;
                margin: 0;
                padding: $gutter/2 0;
            }
        }
    }
}
