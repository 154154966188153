@import "../bootstrap/scss/bootstrap";
@import "~lightpick/css/lightpick.css";

@import "css/all.min.css";

@import "modules/_variables";
@import "modules/_donut";
@import "modules/_typo";
@import "modules/_subnav";
@import "modules/_slider";
@import "modules/_slideshow";
@import "modules/_galery";
@import "modules/_elements";
@import "modules/_forms";
@import "modules/_buttons";
@import "modules/_animation";
@import "modules/_popup";
@import "modules/uploader";
@import "modules/_modal";
@import "modules/_accordion";
@import "modules/_datatables";
@import "modules/_loader";
@import "modules/_grid";


@import "_header";
@import "_footer";
@import "_login";
@import "_userProfile";
@import "_proPage";
@import "_marketplace";
@import "_home";
@import "_order";
@import "_product";
@import "_landing";
@import "_brands";
@import "_pros";
@import "_offresfid";



@import "_spcr";
.v-select.cartes-select.form-control .vs__dropdown-toggle{
  border: none;
  height: 42px;
}
.v-select.cartes-select.form-control .vs__dropdown-menu{
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.select2-selection{
    position: relative;
}
.select2-container--default .select2-selection--single .select2-selection__rendered {
    position: absolute;
}
.select2-container, .select2-selection {
    height: 42px !important;
  }
  .select2-selection__rendered{
    top: 50%;
    transform: translateY(-50%);
  }

//=========================
// Bugfix during transition
//=========================
[v-cloak] {
    display: none;
}
.modal-backdrop.show,
.modal-backdrop.in{
    display: none !important;
}
.modal {
    top: 100px !important;
}
@media (min-width: 1025px) and (max-width: 1024px) {
    #body .m-header--fixed .m-body {
        padding-top: 20px!important;
    }
}
.mobilClientDelete.bloc-editing .modal-editing .bottom{
    position: fixed;
}
.mobilClientDelete.bloc-editing .modal-editing .top{
    min-height: 250px;
}

body {
    background: #e9ebee;
}

.tab-pane {
    display: none;
}

.tab-pane.active {
    display: block;
}
.cashless-validation{
    .bloc{
        float: none;
        padding: 32px 24px;
        /* text-align: center; */
        margin: 32px auto;
        max-width: 500px;
    }
}

.question-select {
  .vs__search::placeholder {
    color: #bec4d7 !important;
  }

  .vs__dropdown-toggle {
    min-height: 42px;
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
}

.inscription-pdv-documents {
  @include media-breakpoint-down(md) {
    margin-left: 0;
    margin-right: 0;
  }
  margin-left: 5%;
  margin-right: 5%;
}

.no-min-height-on-mobile.bloc {
  @include media-breakpoint-down(md) {
    min-height: auto !important;
    height: auto !important;
  }
}

.form-bloc {
  min-height: auto !important;
  border-radius: 4px !important;
  border: 1px solid #e0e6f1 !important;
  box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1) !important;
}

// Permet de faire des faux header dans les tables
.fake-head {
  color: #606266 !important;
  vertical-align: bottom !important;
  border-bottom: 1px solid #dcdfe6 !important;
  font-weight: bold;
  background: linear-gradient(#f4f5f8,#f1f3f6) !important;
}

.dateTransaction{
  display: flex;
  justify-content: space-between;
  .datetime-picker, .vue__time-picker {
    width: 45% !important;
    input, input.display-time {
      outline: 0 none !important;
      display: block;
      width: 100% !important;
      padding: 0.375rem 0.75rem !important;
      font-size: 1rem !important;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da !important;
      border-radius: 0.25rem;
      transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
      height: calc(2.25rem + 2px) !important;
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
}

.vue__time-picker .dropdown ul li:not([disabled]).active, .vue__time-picker .dropdown ul li:not([disabled]).active:hover, .vue__time-picker .dropdown ul li:not([disabled]).active:focus, .vue__time-picker-dropdown ul li:not([disabled]).active, .vue__time-picker-dropdown ul li:not([disabled]).active:hover, .vue__time-picker-dropdown ul li:not([disabled]).active:focus {
  background: #3bb4f2 !important;
  color: #fff;
}


.btn.icon.btnDatatableMobil{
  width: 25px !important;
  height: 25px !important;
  padding: 0;
  line-height: 25px;
  margin-bottom: 0;
  border-radius: 25px;
  font-size: 13px;
}
.btn.icon.btnDatatableMobil i{
  margin: 0;
}
.vgt-table.mobil{
  font-weight: 300;
  td{
    border-top: none;
  }
}

.modalMobilFacturation .modal .modal-dialog{
  max-width: 90% !important;
}

.bodyModalMobil .modal {
  background: rgba(0, 0, 0, 0.8);
  top: 0 !important;
  padding-right: 0 !important;
}

.enseigne-title{
  font-size: 20px;
  font-weight: bolder;
  margin-top: 1rem;
  text-transform: uppercase
}

