.center-content {
    max-width: 600px;
    margin: auto;
}

.validation-inscription {
    padding: $gutter;
    @include media-breakpoint-down(sm) {
        padding: $gutter/4*3 $gutter/2;
    }
    max-width: 500px;
    margin: auto;

    .check {
        font-size: 68px;
        color: #e0e6f1;
        margin: 0 auto 24px;
    }
}
