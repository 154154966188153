@media (max-width: 440px) {
  .col-xs-hd {
    display: none;
  }
  .col-xs-9{
    width: 75% !important;
  }
  .col-xs-4{
     width: 33.3333333% !important;
   }
  .col-xs-5{
    width: 41.66666667% !important;
  }
  .col-xs-7{
    width: 58.33333333% !important;
  }
  .col-xs-8{
    width: 66.66666667% !important;
  }
}