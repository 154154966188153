.modal-section {
  position: fixed;
  height: 100vh;
  width: 100vw;
  background: rgba(55, 54, 96, 0.40);
  top: 0;
  left: 0;
  z-index: 11111;
  @include media-breakpoint-down(sm) {
    background: rgb(255, 255, 255);
  }

  .modal-popup {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, 0);
    max-width: 480px;
    max-height: 70%;
    overflow: auto;
    @include media-breakpoint-down(sm) {
      top: 0;
      left: 0;
      transform: translate(0, 0);
      width: 100%;
      height: 100%;
      max-height: 100%;
      border: none;
      padding: 0 0 0;
      text-align: center;
      overflow: auto;
    }
  }
  .modal-popup.medium {
    max-width: 680px;
    @include media-breakpoint-down(sm) {
      max-width: 100%;
    }
  }

  .top {
    padding: $gutter/4*3 $gutter/4*3 $gutter/2;
  }

  .content {
    padding: 0 $gutter/4*3;
  }

  .bottom {
    padding: $gutter/2 $gutter/4*3
  }

  .bottom {
    position: sticky !important;
    bottom: 0;
    background: #fff;

    .action {
      .btn {
        margin: 0 $gutter/2;
        width: auto;
      }
    }
  }



  @include media-breakpoint-down(sm) {
    .bottom {
      padding: 16px 24px 32px;
      position: sticky !important;
      bottom: 0;
      left: 0;
      width: 100%;
      background: #fff;

      .action {
        .btn {
          width: calc(50% - 32px);
        }
      }
    }
  }
}

.tooltip-container {
  position: relative;

  .tooltip-icon {
    cursor: pointer;
    color: #5f5aef;
  }

  .tooltip-popover {
    @extend .bloc;
    position: absolute;
    display: block;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    margin: 30px auto;
    width: 400px;
    padding: 0;

    @include media-breakpoint-down(sm) {
      position: fixed;
      top: 0;
      left: 0;
      margin: 0;
      height: 100%;
      width: 100%;
      z-index: 1111;
    }

    .top {
      margin: 0;
      padding: 8px 24px;
      background: #5f5aef;
      color: #fff;
      border-radius: 4px 4px 0 0;

      .tooltip-title {
        @extend .d-flex;
        align-items: center;
        color: #fff;
        margin: 0;
        font-size: 11px;
        text-transform: uppercase;
        font-weight: 600;

        .tooltip-close {
          @extend .ml-auto;
          cursor: pointer;
        }
      }
    }

    .content {
      padding: 20px 24px;
      text-align: justify;

      p {
        font-size: 13px;
      }
    }
  }
}

.tooltip-popover {
  &:before {
    content: "";
    position: absolute;
    top: -11px;
    left: 7px;
    width: 0;
    height: 10px;
    border-bottom: 9px solid #e2e4e8;
    border-right: 9px solid transparent;
    border-left: 9px solid transparent;
  }

  &:after {
    content: "";
    position: absolute;
    top: -8px;
    left: 8px;
    width: 0;
    height: 8px;
    border-bottom: 8px solid #5f5aef;
    border-right: 8px solid transparent;
    border-left: 8px solid transparent;
  }
}
