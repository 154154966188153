



/* ==========================
      LOGIN BLOC
========================== */


.user-login {
    text-align: center;
    padding: 80px 0;
    @media (max-width: 768px) {
        padding: 0;
    }

    .bloc {
        width: 100%;
        margin: 0 auto;
        z-index: 1;
        max-width: 480px;
    }

    .title {margin-bottom: 16px;}

    .subtitle {margin-bottom: 24px;}

    .header {margin: 40px 40px 0;}

    .form {margin: 0 40px 120px;}

    .form {
        .btn {
            width: 100%;
        }
    }

    .forgot-password-content,
    .check-email-content {
        display: none;
    }

    #contentValidation {
        display: none;
        margin-bottom: 120px;

        .btn-secondary {
            display: table;
            cursor: pointer;
            margin: auto;
        }

        .icon {
            margin: 60px auto 32px;
            display: block;
            font-size: 60px;
            text-align: center;
            color: #bec4d7;
        }
    }

    .login-content,
    .forgot-password-content,
    .check-email-content {
        position: relative;
        margin: 0 auto;
        max-width: 400px;

        .show-password {
            position: absolute;
            top: 1px;
            right: 1px;
            bottom: 1px;
            padding: 14px 12px;
            border-radius: 0 4px 4px 0;
            margin: auto;
            color: #5f5aef;

            svg {margin-bottom: 3px;}
        }

        input[type="password"] {color: #5e59f0;}

        input[type="password"] + .show-password path {fill: #5e59f0;}

        .forgot-password,
        .check-email,
        .not-registered {
            font-size: 12px;
            text-decoration: none;
            color: #80889c;
            text-align: center;
            margin: 16px 0 8px;

            a {
                color: #5b6a9a;
                font-size: 12px;
                font-weight: 600;
                text-decoration: underline;
            }
        }

        .not-registered {margin: 0 0 8px;}

        .fa-check-circle {
            text-align: center;
            margin: 60px auto 20px;
            display: block;
            font-size: 60px;
            color: #7a79ec45;
        }
    }

    .secur-message {
        background-color: #e8f9f1;
        margin: 0;
        padding: 12px 16px;
        text-align: center;
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;

        span {
            color: #00c373;
            font-size: 12px;
            font-weight: 600;

            img {
                margin: 0 0 5px 0;
            }
        }
    }

    .get-my-password,
    .check-my-email {
        cursor: pointer;
    }

    @media (max-width: 768px) {

        .user-login, .user-register {
            min-height: 600px;
        }

        .bloc {
            border-radius: 0;
            background-color: #ffffff;
            border: 1px solid #fff;
            box-shadow: none;
            max-width: none;
            min-height: 600px;
            height: 100%;
            position: relative;
            top: 0;
            transform: translateY(0);

            .header {
                margin: 36px auto 0;
                max-width: 320px;
            }

            .form {
                margin: 0 auto 80px;
                max-width: 320px;
            }
        }

        .login-content,
        .forgot-password-content,
        .check-email-content {
            width: 100%;
        }
    }
}














/* ==========================
      REGISTER BLOC
========================== */


.user-register {
    text-align: center;
    padding: 10px 0;
    @media (max-width: 768px) {padding: 0;}

    .bloc:not(.modal-editing) {
        width: 100%;
        max-width: 800px;
        margin: 0 auto;
        z-index: 1;
    }

    .register-content {
        display: flex;
    }

    .register-form {
        width: 100%;
        max-width: 400px;
        min-height: 600px;
        margin: 40px auto;
        padding: 0 40px;
    }

    .register-validation {
        display: none;
        margin: 120px 80px;
    }

    .register-image {
        width: 50%;
        background: #a8a6f8;
        padding: 0;

        img {
            width: 100%;
        }
    }

    .header {margin: 40px 40px 0;}

    .title {margin-bottom: 16px;}

    .subtitle {margin-bottom: 24px;}

    #contentValidation {
        display: none;
        margin-bottom: 120px;

        .btn-secondary {
            display: table;
            cursor: pointer;
            margin: auto;
        }

        .icon {
            margin: 60px auto 32px;
            display: block;
            font-size: 60px;
            text-align: center;
            color: #bec4d7;
        }
    }

    .show-password {
        position: absolute;
        top: 1px;
        right: 1px;
        bottom: 1px;
        padding: 14px 12px;
        border-radius: 0 4px 4px 0;
        margin: auto;
        color: #5f5aef;

        svg {
            margin-bottom: 3px;
        }
    }

    input[type="password"] {color: #5e59f0;}

    input[type="password"] + .show-password path {fill: #5e59f0;}

    .check-email,
    .not-registered {
        font-size: 12px;
        text-decoration: none;
        color: #80889c;
        text-align: center;
        margin: 16px 0 8px;

        a {
            color: #5b6a9a;
            font-size: 12px;
            font-weight: 600;
            text-decoration: underline;
        }
    }

    .not-registered {margin: 0 0 8px;}

    .fa-check-circle {
        text-align: center;
        margin: 60px auto 20px;
        display: block;
        font-size: 60px;
        color: #7a79ec45;
    }

    .secur-message {
        background-color: #e8f9f1;
        margin: 24px 0 0;
        padding: 12px 16px;

        span {
            color: #00c373;
            font-size: 12px;
            font-weight: 600;

            img {
                margin: 0 0 5px 0;
            }
        }
    }

    @media (max-width: 768px) {

        .user-register {
            min-height: 600px;
        }

        .bloc {
            border-radius: 0;
            background-color: #ffffff;
            border: 1px solid #fff;
            box-shadow: none;
            max-width: none;
            min-height: 600px;
            height: 100%;
            //position: relative;
            top: 0;
            //transform: translateY(0);

            .header {
                margin: 36px auto 0;
                max-width: 320px;
            }
        }

        .register-content,
        .register-validation {
            width: 100%;
        }
    }
}
.user-register .bloc .header {
    margin: 0;
}
.register-logo {
    width: 130px;
    @media (max-width: 768px) {
        width: 100px;
    }
}





/* ========================== */


.madora {
    .user-register {
        .bloc {
            max-width: 480px;
            @media (max-width: 768px) {
                max-width: none;
            }
        }
    }
}

.user-register .bloc .header {
    margin: 0;
}
.register-logo {
    width: 130px;
    @media (max-width: 768px) {
        width: 100px;
    }
}

.form-questions {
    text-align: left;
    .question {
        font-size: 13px;
        font-weight: bold;
        color: #212529;
        .marker {display: inline-block;}
        .checkmark {display: inline-block;margin-right: 24px;}
    }
}


