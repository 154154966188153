.accordion {
    @extend .bloc;
    overflow: hidden;
    margin-bottom: 16px;

    .accordion-action {
        @extend .d-flex, .align-items-center;
        padding: $gutter/4*3;
        @include media-breakpoint-down(sm) {
            padding: $gutter/4*3 $gutter/2;
        }
        font-weight: 500;
        font-size: 15px;
        cursor: pointer;
        transition: all 0.24s ease;

        .icon {
            font-size: 12px;
            margin: 0;
            transition: all 0.24s ease;
        }
    }
    .accordion-action[aria-expanded="true"] {
        background: #5f5be9;
        color: #fff;

        &.green {
            background: #6cd899;
            color: #fff;
        }

        &.red {
            background: #ff7889;
            color: #fff;
        }
    }

    .accordion-action[aria-expanded="true"] {
        .icon {
            transform: rotate(90deg);
            color: #fff;
        }
    }

    .accordion-body {
        padding: $gutter/4*3;
        @include media-breakpoint-down(sm) {
            padding: $gutter/4*3 $gutter/2;
        }
    }
}
