.footer {
    background: #FFF;
    padding: 40px 0 24px;

    .container {

        .footer-logo {
            padding-left: 25px;
            margin-bottom: 10px;

            img {
                height: 48px;
            }
        }

        .footer-underline {
            font-size: 12px;
            color: #5e53f3;
        }

        .footer-address {
            span {
                float: left;
                margin-top: 2px;
                color: #b3b8d3;
                line-height: 22px;
            }

            p {
                padding-left: 24px;
                font-size: 12px;
            }
            a {
                font-size: 12px;
                color: #99a1c3;
            }
        }

        h3 {
            font-weight: 600;
            font-size: 16px;
            color: #5b6a9a;
            margin-bottom: 16px;
        }

        .p {
            display: block;
            font-weight: 400;
        }

        .footer-separator {
            width: 32px;
            height: 1px;
            background: rgba(91, 106, 154, 0.2);
            display: block;
            margin: 24px 0;
        }

        .social {
            color: #5c57f2;
            font-size: 18px;
        }
    }
}

.sub-footer {
    background: #f4f7fb;
    .copyright {
        font-size: 13px;
        font-weight: 600;
        color: #99a1c3;
        padding: 24px 0;
        display: block;
    }
}



// =========================================
// Medium devices (tablet & landscape phones)
// =========================================


@include media-breakpoint-down(sm) {
    .footer {
        .container {


        }
    }
}