

* {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	font-family: 'Montserrat',sans-serif;
	font-size: 100%;
}

.title {
	font-size: 30px;
	font-weight: 800;
	color: $color-primary;
	margin-bottom: 32px;
	padding: 0;
}

h1, .h1 {
	font-size: 30px;
	font-weight: 600;
	color: #5b6a9a;
	margin-bottom: 24px;
	padding: 0;

}

h2, .h2 {
	font-size: 18px;
	font-weight: 600;
	color: #5b6a9a;
	margin-bottom: 16px;
	padding: 0;
}


h3, .h3 {
	font-size: 18px;
	font-weight: 600;
	color: #37385e;
	margin-bottom: 16px;
}


h4, .h4 {
	font-size: 14px;
	font-weight: 600;
	color: #5b6a9a;
	margin-bottom: 8px;
}


p, .p {
	font-size: 15px;
	font-weight: 500;
	color: #37385e;
	//color: #99a1c3;
	//color: #5b6a9a;
	line-height: 22px;
	margin-bottom: 8px;
	padding: 0;
}

.text-lighter {
    color: #7a8da3;
	font-size: 14px;
}

.sub-header {
	font-size: 14px;
	font-weight: 500;
	margin-bottom: 0;
	padding: 0;
}

.caption {
	font-size: 13px;
	font-weight: 500;
	margin-bottom: 0;
	padding: 0;
}

.marker {
	font-size: 11px;
	text-transform: uppercase;
	font-weight: 600;
	color: #94a4b5;
}

.link {
	color: #5f5aef;
	font-weight: bold;
	text-decoration: underline;
	cursor: pointer;
	&:hover {
		opacity: .8;
	}
}


a {
    &:hover {
        color: #5f5aef;
        text-decoration: none;
    }
}


.text-error {
    color: #ff7889;
    font-weight: 500;
}



.fa, .fas {
    font-weight: 900;
    color: inherit;
    margin: 0 6px;
    padding: 0;
    font-size: 16px;
}


/*


[class*="section-slider"] h2 {margin-bottom: 0;}

*/






//=============================
//      VERSION TABLETTE
//=============================
@include media-breakpoint-down(md) {}







//=============================
//      VERSION MOBILE
//=============================
@include media-breakpoint-down(sm) {
	.title {
		font-size: 22px;
	}
	h1, .h1 {
		font-size: 22px;
	}
	h2, .h2 {
		font-size: 16px;
		font-weight: 600;
	}
	h3, .h3 {
		font-size: 14px;
		font-weight: 500;
	}
	h4, .h4 {
		font-size: 13px;
	}
	p, .p {
		font-size: 13px;
	}
	.text-lighter {
		font-size: 13px;
	}
	.sub-header {
		font-size: 13px;
	}
	.caption {
		font-size: 12px;
	}
}
