.pros {
    .container {
        padding-bottom: 0;
        padding-top: 10px;
    }

    .filter-btn {
        color: #5f5aef;
        font-size: 14px;
        line-height: 35px;
        font-weight: 600;
        text-align: right;
        margin-bottom: 0;
        cursor: pointer;

        i {
            font-size: 12px;
            transition: all 0.3s ease;
        }

        &[aria-expanded="true"] {
            i {
                transform: rotate(90deg);
            }
        }
    }

    .recherche {
        border: none;
        outline: none;
        font-size: 20px;
        padding: 10px;
    }

    .custom-checkbox {
        margin-right: 5px;
    }

    #shop-filter {
        h3 {
            margin-bottom: 24px;
            margin-top: 16px;
        }

        .custom-checkbox .switch {
            margin-left: 4px;
        }

        .custom-checkbox {
            margin: 0 0 16px 0;
            @include media-breakpoint-down(sm) {
                padding: 16px 0;
                margin: 0;
                border-bottom: 1px solid #e0e6f1;
            }
        }
    }

    .pro-cart {
        display: inline-block;
        overflow: hidden;
        width: 450px;
        border: 1px solid #bec4d7;
        margin: 5px;

        @media (max-width: 1025px) {
            // margin: 10px auto;
            // display: block;
            width: calc(50% - 10px);
        }
        @media (max-width: 735px) {
            margin: 10px auto;
            display: block;
            width: 450px;
        }

        .shop-logo {
            height: 80px;
            width: 80px;
            overflow: hidden;
            margin-left: 10px;
            border-radius: 5px;
            background: url(/images/brands-hero.jpg?d0b4bf8…) center bottom no-repeat;
        }

        @media (max-width: 500px) {
            width: 100%;
            .shop-logo {
                height: 50px;
                width: 50px;
            }
        }

        .content {
            p {
                line-height: 17px;
            }

            flex: 2;
            padding: 10px 10px 10px 15px;

            .d-flex.align-items-center {
                clear: both;
            }

            .opened {
                float: right;
            }

            h3 {
                font-weight: bolder;
                margin: 0;
                font-size: 14px;
            }

            .tel.marker {
                font-size: 12px;
                float: right;
            }

            .adresse {
                font-size: 12px;
            }
        }

        .content-btn {
            .btn-square {
                display: flex;
                height: 48px;
                width: 40px;
                background: #e0e6f1;
                border-left: 1px solid #bec4d7;
                align-items: center;
                justify-content: center;
                margin: 0;


                &:hover {
                    background: #5f5aef;
                    color: #fff;
                    cursor: pointer;
                }

                &.disabled:hover {
                    background: #e0e6f1;
                    color: inherit;
                    cursor: initial;
                }

                &.fa-envelope,
                &.fa-map {
                    border-top: 1px solid #bec4d7;
                }
            }
        }

        .opened {
            font-size: 11px;
            font-weight: 800;
            margin-left: auto;
            color: #2ed05b;
            position: relative;

            i {
                font-size: 10px;
            }
        }
    }
}