
// Structure

html {background-color: #fff}

body {
    width: 100%;
    margin: 0 auto;
    background-color: #fff;
    color: #1f3349;
}
.m-body {background-color: #e9ebee !important;}

.template-content {
    max-width: 1240px;
    margin: 0 auto;
    background: #f4f7fb;
    padding-bottom: 64px;
    @include media-breakpoint-down(sm) {
        padding-bottom: 0;
    }
}
.stick {
    display: block !important;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 11;
    background: #fff;
    -webkit-box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.32);
    box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.32);

    .container {
        padding: 0 $gutter/2;
        @include media-breakpoint-down(sm) {
            padding: $gutter/2;
        }
    }
}
section {
    .container {
        padding-top: $gutter;
        padding-bottom: $gutter;
        @include media-breakpoint-down(md) {
            padding-top: $gutter/4*3;
            padding-bottom: $gutter/4*3;
        }
        @include media-breakpoint-down(sm) {
            padding-top: $gutter/2;
            padding-bottom: $gutter/2;
        }
    }
}


.container {
    max-width: 960px !important;

    @include media-breakpoint-down(md) {
        max-width: 896px !important;
    }

    @include media-breakpoint-down(sm) {
        max-width: 100% !important;
    }

    @include media-breakpoint-down(xs) {
        max-width: 100% !important;
    }
}


.row.half {
    margin-left: -$grid-gutter-width/4 !important;
    margin-right: -$grid-gutter-width/4 !important;

    & [class^="col"] {
        padding-left: $grid-gutter-width/4 !important;
        padding-right: $grid-gutter-width/4 !important;
    }
}

[v-cloak] {
    display: none;
}


.bloc-style,
.bloc {
    position: relative;
    border-radius: 4px;
    background-color: #ffffff;
    border: 1px solid #e0e6f1;
    box-shadow: 0 6px 24px 0 rgba(74, 144, 226, 0.1);
    word-wrap: break-word;

    .top {
        padding: $gutter/4*3 $gutter/4*3 $gutter/2 ;
    }
    .content {
        padding: $gutter/2 $gutter/4*3;
    }
    .bottom {
        padding: $gutter/4*3 $gutter/4*3 $gutter/2;
    }
}


.overflow-h {overflow: hidden;}

/*.separator {
    width: 100%;
    background: #e0e6f1;
    height: 1px;
    margin: 0 0 2px;
}*/

.placeholder {
    border: solid 1px #e0e6f1;
    background: #e0e6f070 !important;
    box-shadow: none;
    padding: $gutter/2 $gutter;
    position: relative;

    .content {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        transform: translateY(-50%);

        p{
            font-style: italic;
            font-size: 14px;
        }
    }
}

.icon-style {
    font-size: 32px;
    font-weight: bold;
    color: rgba(95, 89, 239, 0.24);
}



.mobile-only {
    display: none !important;
}

.desktop-only {
    display: block !important;
}

@include media-breakpoint-down(sm) {
    .mobile-only {
        display: block !important;
    }
    .desktop-only,
    .hide-mobile {
        display: none !important;
    }
}



// *********************************************
//
//       CLASS DE STYLE FONCTIONNELLES
//
// *********************************************


// ********* Position *********
.p-initial {position: initial !important;}
.p-absolute {position: absolute !important;}
.p-fixed {position: fixed !important;}


// ********* Bordures *********
.br {border-right: 1px solid #e7e7e7;}
.bb {border-bottom: 1px solid #e7e7e7;}
.bl {border-left: 1px solid #e7e7e7;}
.bt {border-top: 1px solid #e7e7e7;}


// ********* Centrage de text *********
.text-center {text-align: center;}
.text-right {text-align: right;}
.text-left {text-align: left;}


// ********* Margins *********
.m-60 {margin: 60px !important;}
.mt-60 {margin-top: 60px !important;}
.mr-60 {margin-right: 60px !important;}
.mb-60 {margin-bottom: 60px !important;}
.ml-60 {margin-left: 60px !important;}

.m-32 {margin: 32px !important;}
.mt-32 {margin-top: 32px !important;}
.mr-32 {margin-right: 32px !important;}
.mb-32 {margin-bottom: 32px !important;}
.ml-32 {margin-left: 32px !important;}

.m-24 {margin: 24px !important;}
.mt-24 {margin-top: 24px !important;}
.mr-24 {margin-right: 24px !important;}
.mb-24 {margin-bottom: 24px !important;}
.ml-24 {margin-left: 24px !important;}

.m-16 {margin: 16px !important;}
.mt-16 {margin-top: 16px !important;}
.mr-16 {margin-right: 16px !important;}
.mb-16 {margin-bottom: 16px !important;}
.ml-16 {margin-left: 16px !important;}

.m-8 {margin: 8px !important;}
.mt-8 {margin-top: 8px !important;}
.mr-8 {margin-right: 8px !important;}
.mb-8 {margin-bottom: 8px !important;}
.ml-8 {margin-left: 8px !important;}

.m-0 {margin: 0 !important;}
.mt-0 {margin-top: 0 !important;}
.mr-0 {margin-right: 0 !important;}
.mb-0 {margin-bottom: 0 !important;}
.ml-0 {margin-left: 0 !important;}

// .m-auto {margin: auto !important;}
// .mt-auto {margin-top: auto !important;}
// .mr-auto {margin-right: auto !important;}
// .mb-auto {margin-bottom: auto !important;}
// .ml-auto {margin-left: auto !important;}


// ********* Paddings *********
.p-60 {padding: 60px !important;}
.pt-60 {padding-top: 60px !important;}
.pr-60 {padding-right: 60px !important;}
.pb-60 {padding-bottom: 60px !important;}
.pl-60 {padding-left: 60px !important;}

.p-32 {padding: 32px !important;}
.pt-32 {padding-top: 32px !important;}
.pr-32 {padding-right: 32px !important;}
.pb-32 {padding-bottom: 32px !important;}
.pl-32 {padding-left: 32px !important;}

.p-24 {padding: 24px !important;}
.pt-24 {padding-top: 24px !important;}
.pr-24 {padding-right: 24px !important;}
.pb-24 {padding-bottom: 24px !important;}
.pl-24 {padding-left: 24px !important;}

.p-16 {padding: 16px !important;}
.pt-16 {padding-top: 16px !important;}
.pr-16 {padding-right: 16px !important;}
.pb-16 {padding-bottom: 16px !important;}
.pl-16 {padding-left: 16px !important;}

.p-0 {padding: 0 !important;}
.pt-0 {padding-top: 0 !important;}
.pr-0 {padding-right: 0 !important;}
.pb-0 {padding-bottom: 0 !important;}
.pl-0 {padding-left: 0 !important;}


hr {
    margin: $gutter/4*3 0;
    border-top: 1px solid #e0e6f0;
}

.back-btn {
    display: inline-block;
    color: #bec4d7;
    font-size: 16px;
    font-weight: 600;
    margin: 0 0 4px 0;

    .fa-play {
        transform: rotate(-180deg);
        border-radius: 1px;
        color: #bec4d7;
        font-size: 11px;
        margin: 0 4px 0 3px;
        padding: 1px 0 0;
    }

}


.bloc > .row {
    padding: 0 16px;
}





#errorLogin,
#errorPassword {
    display: none;
}






.tag {
    padding: 16px;

    .pin {
        float: left;
        position: relative;
        top: 0;
        right: 0;
        background: #5f59ef;
        border-radius: 40px;
        padding: 2px 8px;
        margin: 0 8px 0 0;
        color: #fff;
        font-size: 12px;
        font-weight: 600;
    }
}


ul{
    list-style-type: none;
}

.focus {
    border: 2px dashed #5f5be8;
    display: inline-block;
    background: #f4f7fb;
    padding: 16px;
    border-radius: 4px;
    margin: 24px 0;
}


